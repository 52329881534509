import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#29C475',
        primaryLight: '#A9EDC8',
        secondary: '#F46451',
        secondaryLight: '#FFCAB8',
        accent: '#8c9eff',
        error: '#F46451',
        lightGray: '#fbfbfb',
        gray: '#545454'
      },
      dark: {
        primary: '#29C475',
        primaryLight: '#A9EDC8',
        secondary: '#F46451',
        secondaryLight: '#FFCAB8',
        accent: '#8c9eff',
        error: '#F46451'
      }
    }
  }
})
