<template>
  <div>
  <ConditionsListTable></ConditionsListTable>
  <CreditorConditionsListTable></CreditorConditionsListTable>
    </div>
</template>

<script>
import ConditionsListTable from '../components/ConditionsListTable'
import CreditorConditionsListTable from '../components/CreditorConditionsListTable'
export default {
  name: 'PageConditions',
  components: { ConditionsListTable, CreditorConditionsListTable }
}
</script>
