import axios from 'axios'

const state = {
  statsMonitor: null,
  historyMonitor: null,
  historyAlerts: null
}
const getters = {
  StateStatsMonitor: (state) => state.statsMonitor,
  StateHistoryMonitor: (state) => state.historyMonitor,
  StateHistoryAlerts: (state) => state.historyAlerts
}
const actions = {
  async FetchStatsMonitor ({ commit }, creditor) {
    const myPath = '/monitoring/statscurrstate/' + creditor
    await axios.get(myPath).then(response => {
      commit('setStatsMonitor', response.data)
    })
  },
  async FetchHistoryMonitor ({ commit }, creditor) {
    const myPath = '/monitoring/statshistory/' + creditor
    await axios.get(myPath).then(response => {
      commit('setHistoryMonitor', response.data)
    })
  },
  async FetchHistoryAlerts ({ commit }, creditor) {
    const myPath = '/alerts/statsalerts/' + creditor
    await axios.get(myPath).then(response => {
      commit('setHistoryAlerts', response.data)
    })
  }
}
const mutations = {
  setStatsMonitor (state, stats) {
    state.statsMonitor = stats
  },
  setHistoryMonitor (state, history) {
    state.historyMonitor = history
  },
  setHistoryAlerts (state, alerts) {
    state.historyAlerts = alerts
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
