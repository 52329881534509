<template>
  <div class="small">
    <line-chart :chart-data="datacollection" :options="lineOptions"></line-chart>
  </div>
</template>

<script>
import LineChart from './LineChart'
import { mapActions, mapGetters } from 'vuex'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import localizedTime from 'dayjs/plugin/localizedFormat'
import 'dayjs/locale/sv'
dayjs.locale('sv')
dayjs.extend(relativeTime)
dayjs.extend(localizedTime)
export default {
  name: 'RequestHistory',
  components: { LineChart },
  data: () => ({
    datacollection: {},
    lineOptions: {
      legend: { display: false },
      title: {
        display: false,
        text: 'Antal aktiveringar'
      }
    }
  }),
  computed: {
    ...mapGetters({ History: 'StateHistoryMonitor' }),
    creditor: function () {
      return this.$store.getters.StateUser.creditorId
    },
    historyLabels: function () {
      if (this.History == null) return []
      const t = this.History.map(d => this.labelDescription(d.date_created__month, d.date_created__year))
      return t
    },
    historyValues: function () {
      if (this.History == null) return []
      const t = this.History.map(d => d.count)
      return t
    }
  },
  created () {
    try {
      this.FetchHistoryMonitor(this.creditor)
    } catch (e) {
      throw new Error(e)
    }
    this.unwatchHistoryMonitor = this.$store.watch(
      (state, getters) => getters.StateHistoryMonitor,
      () => {
        this.fillData()
      }
    )
  },
  mounted () {
    this.fillData()
  },
  methods: {
    ...mapActions(['FetchHistoryMonitor']),
    labelDescription (month, year) {
      const d = new Date(year, month - 1, 1)
      const djs = dayjs(d).format('MMM-YY')
      return djs
    },
    fillData () {
      this.datacollection = {
        labels: this.historyLabels,
        datasets: [
          {
            label: 'Antal aktiveringar',
            borderColor: 'rgb(255, 99, 132)',
            data: this.historyValues,
            fill: false
          }
        ]
      }
    }
  },
  beforeDestroy () {
    this.unwatchHistoryMonitor()
  }
}
</script>
