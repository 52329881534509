<template>
<v-icon small :color="getColor()" class="mb-1">{{ getIcon() }}</v-icon>
</template>

<script>
export default {
  name: 'TheIconCheck',
  props: {
    status: {
      required: true,
      type: Boolean,
      default: false
    }
  },
  methods: {
    getColor () {
      if (this.status) return 'primary'
      else return 'secondary'
    },
    getIcon () {
      if (this.status) return 'mdi-check'
      else return 'mdi-cancel'
    }
  }
}
</script>
