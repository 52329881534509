import axios from 'axios'

const state = {
  cashFlow: [],
  tax: [],
  accountActivity: [],
  balanceMonthDay: []
}
const getters = {
  StateCashFlow: (state) => state.cashFlow,
  StateTax: (state) => state.tax,
  StateAccountActivity: (state) => state.accountActivity,
  StateBalanceMonthDay: (state) => state.balanceMonthDay
}
const actions = {
  async FetchCashFlow ({ commit }, companyId) {
    const myPath = '/analysis/chartcf/' + companyId
    await axios.get(myPath).then(response => {
      commit('setCashFlow', response.data)
    })
  },
  async FetchAccountActivity ({ commit }, companyId) {
    const myPath = '/analysis/chartacac/' + companyId
    await axios.get(myPath).then(response => {
      commit('setAccountActivity', response.data)
    })
  },
  async FetchTax ({ commit }, companyId) {
    const myPath = '/analysis/charttp/' + companyId
    await axios.get(myPath).then(response => {
      commit('setTax', response.data)
    })
  },
  async FetchBalanceMonthDay ({ commit }, companyId) {
    const myPath = '/analysis/chartbmd/' + companyId
    await axios.get(myPath).then(response => {
      commit('setBalanceMonthDay', response.data)
    })
  }
}
const mutations = {
  setCashFlow (state, cfData) {
    if (cfData !== '') {
      state.cashFlow = cfData
    }
  },
  setTax (state, taxData) {
    if (taxData !== '') {
      state.tax = taxData
    }
  },
  setAccountActivity (state, aaData) {
    if (aaData !== '') {
      state.accountActivity = aaData
    }
  },
  setBalanceMonthDay (state, bmdData) {
    if (bmdData !== '') {
      state.balanceMonthDay = bmdData
    }
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
