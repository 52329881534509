<template>
  <span>
    {{ humanFriendlyDate() }}
  </span>
</template>

<script>
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import localizedTime from 'dayjs/plugin/localizedFormat'
import 'dayjs/locale/sv'
dayjs.locale('sv')
dayjs.extend(relativeTime)
dayjs.extend(localizedTime)
export default {
  name: 'BaseDate',
  methods: {
    humanFriendlyDate () {
      return dayjs(this.timeStamp).format('ll')
    }
  }
}
</script>

<style scoped>

</style>
