var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"mx-auto mt-5",attrs:{"width":"1200","elevation":"0"}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.MonitoredItems,"search":_vm.search,"sort-by":"date_modified","sortDesc":true,"loading":_vm.isLoading,"loading-text":"Hämtar förfrågningar...","footer-props":{
        showFirstLastPage: true,
        'items-per-page-text': 'Aktiveringar per sida'
      }},on:{"click:row":_vm.showCompany},scopedSlots:_vm._u([{key:"body.prepend",fn:function(){return [_c('tr',[_c('td',{attrs:{"colspan":"3"}},[_c('v-text-field',{staticClass:"mt-2",attrs:{"type":"text","label":"Filtrera på namn eller org. nummer"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('td',{attrs:{"width":"300px"}},[_c('v-select',{attrs:{"label":"Status","items":_vm.columnValueList(),"clearable":"","dense":"","hide-details":"","hide-selected":""},model:{value:(_vm.cert_status),callback:function ($$v) {_vm.cert_status=$$v},expression:"cert_status"}})],1)])]},proxy:true},{key:"item.date_modified",fn:function(ref){
      var item = ref.item;
return [_c('BaseDateTime',{attrs:{"time-stamp":item.date_modified}})]}},{key:"item.cert_status",fn:function(ref){
      var item = ref.item;
return [_c('v-chip',{attrs:{"label":"","outlined":"","color":_vm.getChipColor(item.cert_status),"dark":"","small":""}},[_c('v-icon',{attrs:{"left":"","x-small":""}},[_vm._v(" "+_vm._s(_vm.getChipIcon(item.cert_status))+" ")]),_vm._v(" "+_vm._s(_vm.monitorValue(item.cert_status))+" ")],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }