// store/modules/auth.js

import axios from 'axios'

const state = {
  company: {},
  selectedOrgNumber: null,
  selectedCompanyId: null,
  companyContact: null
}
const getters = {
  StateCompany: (state) => state.company,
  StateSelectedOrgNumber: (state) => state.selectedOrgNumber,
  StateSelectedCompanyId: (state) => state.selectedCompanyId,
  StateCompanyContact: (state) => state.companyContact
}
const actions = {
  async RetrieveCompany ({ commit, dispatch }, orgNumber) {
    const myPath = 'api/company/' + orgNumber + '/'
    await axios.get(myPath).then(response => {
      if (response.status === 200) {
        commit('setCompany', response.data)
        commit('setSelectedCompanyId', response.data.id)
      }
    }).catch(err => {
      throw new Error(`${err.config.url} not found`)
    })
  },
  async RetrieveCompanyContact ({ commit }, orgNumber) {
    const myPath = 'api/contact-info/' + orgNumber + '/'
    await axios.get(myPath).then(response => {
      if (response.status === 200) {
        commit('setCompanyContact', response.data)
      }
    }).catch(err => {
      throw new Error(`${err.config.url} not found`)
    })
  },
  async CreateUpdateCompanyContact ({ dispatch }, data) {
    const Data = new FormData()
    Data.append('email', data.email)
    Data.append('phone_number', data.phoneNumber)
    Data.append('company', data.companyId)
    Data.append('creditor', data.creditorId)
    const myPath = 'api/contact-info/'
    await axios.post(myPath, Data).then(response => {
      if (response.status === 201) {
        return dispatch('RetrieveCompanyContact', Data.get('company'))
      }
    }).catch(err => {
      throw new Error(`${err.config.url} not found`)
    })
  }
}

const mutations = {
  setCompany (state, company) {
    state.company = company
  },
  setSelectedOrgNumber (state, orgNumber) {
    state.selectedOrgNumber = orgNumber
  },
  setSelectedCompanyId (state, companyId) {
    state.selectedCompanyId = companyId
  },
  setCompanyContact (state, contact) {
    state.companyContact = contact
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
