<template>
  <v-card width="1200" class="mx-auto mt-5" elevation="0">
    <v-data-table
        :headers="headers"
        :items="Conditions"
        class="elevation-1"
        :loading="isLoading"
        loading-text="Fetching conditions..."
        hide-default-footer
        readonly="true"
        dense
    >
  <template v-slot:top>
        <v-toolbar flat dark color="primary">
          <v-toolbar-title>Regler</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <span class="text-left">Anpassas i tabellen nedan vid behov</span>
          <v-spacer></v-spacer>
        </v-toolbar>
      </template>
</v-data-table>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'ConditionsListTable',
  data: () => ({
    isLoading: false
  }),
  computed: {
    ...mapGetters({ Conditions: 'StateConditionsGeneral' }),
    headers: function () {
      return [
        {
          text: 'Regel',
          align: 'start',
          value: 'name_descriptive'
        },
        {
          text: 'Period',
          value: 'condition_period_param'
        },
        {
          text: 'Min',
          value: 'condition_min_param'
        },
        {
          text: 'Max',
          value: 'condition_max_param'
        }
      ]
    }
  },
  created: function () {
    this.$store.commit('setConditionsGeneral', [])
    this.unwatchFetchConditions = this.$store.watch(
      (state, getters) => getters.StateConditionsGeneral,
      () => {
        this.isLoading = false
      }
    )
    try {
      this.FetchConditionsGeneral()
    } catch (e) {
      throw new Error(e)
    }
  },
  methods: {
    ...mapActions(['FetchConditionsGeneral'])
  },
  beforeDestroy () {
    this.unwatchFetchConditions()
  }
}
</script>

<style scoped>
.v-data-table
  /deep/
  tbody
  /deep/
  tr:hover:not(.v-data-table__expanded__content) {
  background: #ffffff !important;
}
</style>
