var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"mx-auto",attrs:{"width":"1200","elevation":"0"}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.Alerts,"loading":_vm.isLoading,"show-select":"","item-key":"id","loading-text":"Hämtar Alerts...","search":_vm.search,"footer-props":{
      showFirstLastPage: true,
      'items-per-page-text': 'Alerts per sida'
    }},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v("Alerts")]),_c('v-menu',{attrs:{"left":"","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}])},[_c('v-list',{attrs:{"flat":"","dense":""}},[_c('v-subheader',[_vm._v("RENSA")]),_c('v-list-item',{attrs:{"disabled":_vm.selected <= 0},on:{"click":function($event){return _vm.markAsRead()}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-bell-remove-outline")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Ta bort markerade")])],1)],1),_c('v-subheader',[_vm._v("VISA")]),_c('v-list-item',{attrs:{"disabled":_vm.search === 'displayInfo'},on:{"click":function($event){return _vm.showInfo()}}},[_c('v-list-item-icon',[_c('v-icon',{attrs:{"color":"blue"}},[_vm._v("mdi-information-outline")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Enbart Info")])],1)],1),_c('v-list-item',{attrs:{"disabled":_vm.search === 'displayWarning'},on:{"click":function($event){return _vm.showWarning()}}},[_c('v-list-item-icon',[_c('v-icon',{attrs:{"color":"secondary"}},[_vm._v("mdi-alert-outline")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Enbart Varning")])],1)],1),_c('v-list-item',{attrs:{"disabled":_vm.search === ''},on:{"click":function($event){return _vm.showAll()}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-dots-horizontal-circle-outline")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Alla")])],1)],1)],1)],1)],1)]},proxy:true},{key:"item.msg",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getCatDescription(item.alert_cat))+" ")]}},{key:"item.datetime_created",fn:function(ref){
    var item = ref.item;
return [_c('BaseDateTime',{attrs:{"time-stamp":item.datetime_created}})]}},{key:"item.displayCat",fn:function(ref){
    var item = ref.item;
return [_c('v-icon',{attrs:{"color":_vm.getCatIconColor(item.displayCat)}},[_vm._v(_vm._s(_vm.getCatIcon(item.displayCat)))])]}}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }