<template>
  <v-app>
  <NavigationDrawer v-if="isLoggedIn"></NavigationDrawer>
  <NavBar v-if="isLoggedIn"></NavBar>
  <!-- Sizes your content based upon application components -->
  <v-main class="lightGray">

    <!-- Provides the application the proper gutter -->
    <v-container fluid>

      <!-- If using vue-router -->
      <router-view></router-view>
    </v-container>
  </v-main>

  <v-footer app>
    <!-- -->
  </v-footer>
</v-app>
</template>

<script>

import NavigationDrawer from './components/NavigationDrawer'
import NavBar from './components/NavBar'
export default {
  name: 'App',

  components: {
    NavigationDrawer,
    NavBar
  },

  data: () => ({
    //
  }),
  computed: {
    isLoggedIn: function () { return this.$store.getters.isAuthenticated }
  }
}
</script>
