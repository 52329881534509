<template>
    <v-row class="mt-16">
      <v-col>
        <MaintenanceAlert v-if="showMaintenanceAlert"
                          :start=this.startDateTime
                          :end=this.endDateTime
                          :alert=this.alertText
        ></MaintenanceAlert>
        <Login></Login>
      </v-col>
    </v-row>
</template>

<script>
import Login from '../components/Login.vue'
import MaintenanceAlert from '../components/MaintenanceAlert'
import axios from 'axios'
export default {
  name: 'PageLogin',
  components: { Login, MaintenanceAlert },
  data: () => ({
    showMaintenanceAlert: false,
    startDateTime: null,
    endDateTime: null,
    alertText: null
  }),
  created () {
    const myPath = '/core/schedule/'
    axios.get(myPath).then(response => {
      if (response.data.length > 0) {
        this.showMaintenanceAlert = true
        this.startDateTime = response.data[0].start_datetime
        this.endDateTime = response.data[0].end_datetime
        this.alertText = response.data[0].alert_text
      }
    }).catch(error => {
      console.log(error)
      this.$router.push('maintenance')
    })
  }
}
</script>
