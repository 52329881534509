<template>
  <v-card width="1200" class="mx-auto mt-5" elevation="0">
    <v-data-table
        :headers="headers"
        :items="MonitoredItems"
        :search="search"
        sort-by="date_modified"
        :sortDesc=true
        class="elevation-1"
        :loading="isLoading"
        loading-text="Hämtar förfrågningar..."
        @click:row="showCompany"
        :footer-props=" {
          showFirstLastPage: true,
          'items-per-page-text': 'Aktiveringar per sida'
        }"
    >
      <template v-slot:body.prepend>
        <tr>
          <td colspan="3">
            <v-text-field v-model="search" type="text" label="Filtrera på namn eller org. nummer" class="mt-2"></v-text-field>
          </td>
          <td width="300px">
            <v-select label="Status"
                      v-model="cert_status"
                      :items="columnValueList()"
                      clearable
                      dense
                      hide-details
            hide-selected></v-select>
          </td>
        </tr>
      </template>
      <template v-slot:item.date_modified="{item}">
        <BaseDateTime :time-stamp="item.date_modified"/>
      </template>
      <template v-slot:item.cert_status="{ item }">
        <v-chip
            label
            outlined
            :color="getChipColor(item.cert_status)"
            dark
            small
        ><v-icon left x-small>
          {{ getChipIcon(item.cert_status) }}
        </v-icon>
          {{ monitorValue(item.cert_status)}}
        </v-chip>
      </template>
    </v-data-table>
  </v-card>

</template>

<script>
import { mapActions, mapGetters } from 'vuex'
export default {
  name: 'MonitoringListTable',
  data: () => ({
    isLoading: false,
    search: '',
    status: '',
    cert_status: ''
  }),
  computed: {
    ...mapGetters({ MonitoredItems: 'StateMonitoredItems', User: 'StateUser' }),
    headers: function () {
      return [
        { text: 'Företagsnamn', value: 'company.company_name', align: 'start', sortable: true },
        { text: 'Org. Nummer', value: 'company.org_number', sortable: true },
        { text: 'Uppdaterad:', value: 'date_modified', sortable: true },
        {
          text: 'Status',
          value: 'cert_status',
          sortable: true,
          filter: value => {
            if (!this.cert_status) return true
            return value === this.cert_status
          }
        }
      ]
    }
  },
  created: function () {
    this.isLoading = true
    try {
      this.ListMonitoredItems(this.User.creditorId)
    } catch (error) {
      throw new Error(error)
    }
    this.unwatchListMonitoredItems = this.$store.watch(
      (state, getters) => getters.StateMonitoredItems,
      () => {
        this.isLoading = false
      }
    )
  },
  methods: {
    ...mapActions(['ListMonitoredItems', 'RetrieveCompany']),

    showCompany (item) {
      this.$store.commit('setSelectedOrgNumber', item.company.org_number)
      const myPath = '/company/?q=' + item.company.org_number
      this.$router.push({ path: myPath })
    },

    getChipColor (state) {
      if (state === 'R') return 'black'
      if (state === 'A') return 'green'
      if (state === 'D') return 'red'
      if (state === 'U') return 'red'
      if (state === 'C') return 'blue'
    },
    getChipIcon (state) {
      if (state === 'R') return 'mdi-clock'
      if (state === 'A') return 'mdi-bell'
      if (state === 'D') return 'mdi-bell-cancel'
      if (state === 'U') return 'mdi-bell-cancel'
      if (state === 'C') return 'mdi-cancel'
    },
    monitorValue (state) {
      if (state === 'R') return 'Begärd'
      if (state === 'A') return 'Aktiverad'
      if (state === 'D') return 'Nekad'
      if (state === 'U') return 'Avbruten'
      if (state === 'C') return 'Avslutad'
    },
    columnValueList () {
      const statusArray = this.MonitoredItems.map(d => d.cert_status)
      // eslint-disable-next-line no-return-assign,no-sequences
      const res = statusArray.reduce((acc, curr) => (acc[curr] = this.monitorValue(curr), acc), {})
      // const array = Object.assign({},...statusArray.map(key => ({[key]: ""})));
      const newArray = []
      for (const key in res) {
        const obj = { text: res[key], value: key }
        newArray.push(obj)
      }
      return newArray
    }
  },
  beforeDestroy () {
    this.unwatchListMonitoredItems()
  }
}
</script>
<style scoped>
.v-data-table
  /deep/
  tbody
  /deep/
  tr:hover:not(.v-data-table__expanded__content) {
  background: #ffffff !important;
}
</style>
