<template>
  <v-card width="1200" class="mx-auto mt-5" elevation="0">
  <v-data-table
        :headers="headers"
        :items="CreditorConditions"
        class="elevation-1"
        :loading="isLoading"
        loading-text="Fetching conditions..."
        no-data-text="Inga anpassade regler är uppsatta"
        dense
        hide-default-footer
    >
    <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>Anpassade regler</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <span class="text-left">Värden i denna tabell används vid daglig analys av bankdata</span>
          <v-spacer></v-spacer>
          <v-dialog v-model="createDialog" max-width="400px">
<!--            <template v-slot:activator="{ on, attrs }">
              <v-btn
                  color="primary"
                  dark
                  class="mb-2"
                  v-bind="attrs"
                  v-on="on"
              >Ny Anpassning
              </v-btn>
            </template>-->
            <v-card>
              <v-card-title>
                <span class="headline">Ny anpassning</span>
              </v-card-title>
              <v-card-text>
                <v-container>
                <v-row>
                  <v-col cols="12" class="col-md-4 col-lg-4">
                    <v-text-field
                      v-model="editedItem.name"
                      label="Dessert name"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    color="secondary"
                    text
                    @click="close"
                >Avbryt
                </v-btn>
                <v-btn
                    class="success"
                    text
                    @click="saveNewCondition"
                    :loading="isSubmitted"
                >Spara
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
    <template v-slot:item.actions="{ item }">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
                small
                class="mr-2"
                @click="editCondition(item)"
                v-on="on"
                v-bind="attrs"
                color="primary"
            >mdi-pen
            </v-icon>
          </template>
          <span>Ändra</span>
        </v-tooltip>
      </template>
</v-data-table>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'CreditorConditionsListTable',
  data: () => ({
    isLoading: false,
    createDialog: false,
    isSubmitted: false,
    editedItem: {
      name: 'Rule'
    }
  }),
  computed: {
    ...mapGetters({ CreditorConditions: 'StateConditionsSpecific' }),
    headers: function () {
      return [
        {
          text: 'Regel',
          align: 'start',
          value: 'condition.name_descriptive'
        },
        { text: 'Period', value: 'condition_period_param' },
        { text: 'Min', value: 'condition_min_param' },
        { text: 'Max', value: 'condition_max_param' }
        // {text: 'Hantera', value: 'actions', sortable: false },
      ]
    }
  },
  created: function () {
    this.$store.commit('setConditionsSpecific', [])
    this.unwatchFetchCreditorConditions = this.$store.watch(
      (state, getters) => getters.StateConditionsSpecific,
      () => {
        this.isLoading = false
      }
    )
    try {
      this.FetchConditionsSpecific()
    } catch (e) {
      throw new Error(e)
    }
  },
  methods: {
    ...mapActions(['FetchConditionsSpecific']),
    saveNewCondition () {

    },
    editCondition () {

    },
    close () {
      this.createDialog = false
      this.connectDialog = false
      this.dialogDelete = false
    },
    closeChallengeDialogue () {
      this.showChallengeData = false
      this.$store.commit('setChallengeData', null)
    },
    closeDelete () {
      this.dialogDelete = false
    }
  },
  watch: {
    dialog (val) {
      val || this.close()
    },
    dialogDelete (val) {
      val || this.closeDelete()
    },
    closeNewAppDialog (val) {
      val || this.closeNewAppDialog()
    }
  },
  beforeDestroy () {
    this.unwatchFetchCreditorConditions()
  }
}
</script>
