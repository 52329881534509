<template>
<div class="small">
    <pie-chart :chart-data="datacollection"></pie-chart>
</div>
</template>

<script>
import PieChart from './PieChart'
import { mapGetters, mapActions } from 'vuex'
export default {
  name: 'RequestStatusChart',
  components: { PieChart },
  data: () => ({
    datacollection: {}
  }),
  created () {
    try {
      this.FetchStatsMonitor(this.creditor)
    } catch (e) {
      throw new Error(e)
    }
    this.unwatchStatsMonitor = this.$store.watch(
      (state, getters) => getters.StateStatsMonitor,
      () => {
        this.fillData()
      }
    )
  },
  computed: {
    ...mapGetters({ Stat: 'StateStatsMonitor' }),
    creditor: function () {
      return this.$store.getters.StateUser.creditorId
    },
    statLabels: function () {
      if (this.Stat == null) return []
      const t = this.Stat.map(d => this.labelDescription(d.status))
      return t
    },
    statValues: function () {
      if (this.Stat == null) return []
      const t = this.Stat.map(d => d.count)
      return t
    }
  },
  mounted () {
    this.fillData()
  },

  methods: {
    ...mapActions(['FetchStatsMonitor']),
    labelDescription (val) {
      if (val === 'R') return 'Begärda'
      if (val === 'A') return 'Aktiverade'
      if (val === 'D') return 'Nekade'
      if (val === 'U') return 'Avbrutna'
      if (val === 'C') return 'Inaktiverade'
    },
    fillData () {
      this.datacollection = {
        labels: this.statLabels,
        datasets: [
          {
            label: 'Data One',
            backgroundColor: ['rgb(41,196,117)', 'rgb(54, 162, 235)', 'rgb(255, 205, 86)', 'rgb(86,235,255)', 'rgb(186,235,255)'],
            data: this.statValues
          }
        ]
      }
    }
  },
  beforeDestroy () {
    this.unwatchStatsMonitor()
  }
}
</script>
