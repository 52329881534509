<template>
<v-card width="400" class="mx-auto mb-8" elevation="0" outlined>
  <v-card-title>Planerat underhåll</v-card-title>
  <v-card-subtitle class="mt-2">
    <v-alert color="secondary"
                 text
                 icon="mdi-alert"
                 style="font-size: small"
                 dense
        >{{ this.alert }} <br/><strong>Från:</strong> {{ this.startDateTime}} <br/> <strong>Till</strong> {{ this.endDateTime }}</v-alert>
  </v-card-subtitle>

</v-card>
</template>

<script>
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import localizedTime from 'dayjs/plugin/localizedFormat'
import 'dayjs/locale/sv'
dayjs.locale('sv')
dayjs.extend(relativeTime)
dayjs.extend(localizedTime)
export default {
  name: 'MaintenanceAlert',
  props: ['start', 'end', 'alert'],
  computed: {
    startDateTime: function () {
      return dayjs(this.start).format('llll')
    },
    endDateTime: function () {
      return dayjs(this.end).format('llll')
    }
  }
}
</script>
