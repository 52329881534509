import Vuex from 'vuex'
import Vue from 'vue'
import createPersistedState from 'vuex-persistedstate'
import auth from './modules/auth'
import companies from './modules/companies'
import bankConnections from './modules/bankConnections'
import monitoring from './modules/monitoring'
import alerts from './modules/alerts'
import comments from './modules/comments'
import analysis from './modules/analysis'
import stats from './modules/stats'
import charts from './modules/charts'
import SecureLS from 'secure-ls'
import simpleBrowserFingerprint from 'simple-browser-fingerprint'

const version = process.env.VUE_APP_VERSION
if (!localStorage.getItem('app_version')) {
  localStorage.setItem('app_version', version)
}

const securityOptions = {
  encodingType: '',
  encryptionSecret: undefined,
  isCompression: false
}
if (process.env.NODE_ENV === 'production') {
  Object.assign(securityOptions, {
    encodingType: 'base64',
    encryptionSecret: simpleBrowserFingerprint(),
    isCompression: true
  })
}
const ls = new SecureLS(securityOptions)

Vue.use(Vuex)
export default new Vuex.Store({
  modules: {
    auth,
    companies,
    bankConnections,
    monitoring,
    alerts,
    comments,
    analysis,
    stats,
    charts
  },
  plugins: [
    createPersistedState({
      storage: {
        getItem: (key) => {
          try {
            return ls.get(key)
          } catch (e) {
            window.localStorage.removeItem(key)
          }
        },
        setItem: (key, value) => ls.set(key, value),
        removeItem: (key) => ls.remove(key)
      }
    })
  ]
})
