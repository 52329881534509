<template>
  <v-container>
    <v-card width="400" class="mx-auto" v-if="!otpUrl && !changePw && !otpDeviceConfirmed" elevation="0" outlined>
      <v-app-bar flat color="rgba(0, 0, 0, 0)">
        <v-toolbar-items>
          <v-img
              class="mt-3"
              max-height="30"
              max-width="140"
              src="../assets/Credon_logo.svg">
          </v-img>
        </v-toolbar-items>
      </v-app-bar>
      <v-card-text>
        <v-form @submit.prevent="submitLogin" v-model="isValid">
          <v-text-field
              label="Användarnamn"
              placeholder=" "
              persistent-placeholder
              prepend-icon="mdi-account-circle"
              required
              v-model="form.email"
              :disabled="isSubmitted"
              :rules="emailRules"
              id="username"
          />
          <v-text-field
            label="Lösenord"
            type="password"
            prepend-icon="mdi-lock"
            placeholder=" "
            persistent-placeholder
            required
            v-model="form.password"
            :disabled="isSubmitted"
            :rules="passwordRules"
            id="password"
            />
          <v-card-actions>
          <v-btn type="submit"
                 class="primary"
                 :disabled="!isValid"
                 :loading="isSubmitted"
          >Logga in</v-btn>
          <v-spacer></v-spacer>
          <router-link to="/devportal"></router-link>

            </v-card-actions>
        </v-form>
      </v-card-text>
    </v-card>
    <v-card width="400" class="mx-auto" v-if="!otpUrl && changePw && !otpDeviceConfirmed" elevation="0" outlined>
      <v-app-bar flat color="rgba(0, 0, 0, 0)">
        <v-toolbar-items>
          <v-img
              class="mt-3"
              max-height="30"
              max-width="140"
              src="../assets/Credon_logo.svg">
          </v-img>
        </v-toolbar-items>
      </v-app-bar>
      <v-card-title>Ändra ditt lösenord</v-card-title>
      <v-card-text>Av säkerhetsskäl ber vi dig ändra ditt lösenord.</v-card-text>
      <v-card-subtitle>Lösenordstyrka:</v-card-subtitle>
      <v-card-text>
        <v-form @submit.prevent="submitNewPassword" v-model="isValid">
          <v-progress-linear
              :background-opacity="0.1"
              :color="score.color"
              :value="score.value"
            ></v-progress-linear>
          <v-text-field class="mt-4"
              label="Nytt lösenord"
              type="password"
              required
              v-model="changePwForm.password"
              :disabled="isSubmitted"
              :rules="[changePwRules.required, changePwRules.min, changePwRules.strength]"
              id="newPassword"
          />
          <v-text-field
            label="Repetera nytt lösenord"
            :type="showPassword ? 'text' : 'password'"
            required
            v-model="changePwForm.repPassword"
            :disabled="isSubmitted"
            :rules="confirmPasswordRules"
            append-icon="mdi-eye-off"
            @click:append="showPassword = !showPassword"
            id="repPassword"
            />
          <v-card-actions>
          <v-btn type="submit"
                 class="primary"
                 :disabled="!isValid"
                 :loading="isSubmitted"
          >Uppdatera</v-btn>
          <v-spacer></v-spacer>
          <router-link to="/devportal"></router-link>
            </v-card-actions>
        </v-form>
      </v-card-text>
    </v-card>
    <v-card width="400" class="mx-auto" v-if="otpDeviceConfirmed">
      <v-app-bar flat color="rgba(0, 0, 0, 0)">
        <v-toolbar-items>
          <v-img
              class="mt-3"
              max-height="30"
              max-width="140"
              src="../assets/Credon_logo.svg">
          </v-img>
        </v-toolbar-items>
      </v-app-bar>
      <v-form @submit.prevent="submitCode" v-model="isValidOTP">
      <v-card-text>
        <v-text-field label="Verifieringskod"
                      autofocus
                      v-model="code">
        </v-text-field>
      </v-card-text>
      <v-card-actions>
          <v-btn type="submit"
                 class="primary"
                 :disabled="!isValidOTP"
                 :loading="isSubmitted"
          >Skicka</v-btn>
      </v-card-actions>
      </v-form>
    </v-card>
    <v-card width="400" class="mx-auto" v-if="otpUrl && !otpDeviceConfirmed">
      <v-app-bar flat color="rgba(0, 0, 0, 0)">
        <v-toolbar-items>
          <v-img
              class="mt-3"
              max-height="30"
              max-width="140"
              src="../assets/Credon_logo.svg">
          </v-img>
        </v-toolbar-items>
      </v-app-bar>
      <v-card-title>Tvåfaktorsautentisering</v-card-title>
      <v-card-subtitle>För att komma vidare vill vi att du aktiverar tvåvägsautentisering. Scanna denna QR-kod med mobilappliation som tex. Google Authenticator och skriv sedan in verifieringskoden nedan.</v-card-subtitle>
      <v-card-text>
        <v-row class="mt-2 text-center">
            <v-col cols="12">
              <vue-qrcode :value="otpUrl" :margin=0 :scale=3 />
            </v-col>
        </v-row>
      </v-card-text>
      <v-card-text>
        <v-text-field label="Verifieringskod"
                      v-model="code"
        ></v-text-field>
      </v-card-text>
      <v-card-actions>
          <v-btn @click="submitCode"
                 class="primary"
                 :disabled="!isValid"
                 :loading="isSubmitted"
          >Skicka</v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
    <v-row class="text-center">
      <v-col
        class="mb-5"
        cols="12"
      >
      </v-col>
    </v-row>
    <v-snackbar
      v-model="showError"
      :timeout="timeout"
    >
      {{ snackbarText }}
      <template v-slot:action="{ attrs }">
        <v-btn
          color="blue"
          text
          v-bind="attrs"
          @click="showError = false"
        >Stäng
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex'
import VueQrcode from 'vue-qrcode'
import zxcvbn from 'zxcvbn'
export default {
  name: 'LoginUser',
  components: { VueQrcode },
  data: () => ({
    isSubmitted: false,
    isValid: true,
    isValidOTP: true,
    showError: false,
    snackbarText: 'Login failed',
    showPassword: false,
    timeout: 2000,
    code: null,
    form: {
      email: '',
      password: ''
    },
    changePwForm: {
      password: '',
      repPassword: ''
    },
    emailRules: [
      v => !!v || 'Användarenamn saknas',
      v => /.+@.+/.test(v) || 'Användarnamn måste vara i form av e-post'],
    passwordRules: [
      v => !!v || 'Lösenord saknas'
    ],
    changePwRules: {
      required: value => !!value || 'Skriv ett lösenord',
      min: v => v.length >= 8 || 'Använd minst 8 karaktärer för ditt nya lösenord',
      strength: v => zxcvbn(v).score >= 3 || 'Välj ett starkare lösenord. Prova en mix av bokstäver, siffror, och symboler.'
    },
    validCode: true
  }),
  computed: {
    confirmPasswordRules: function () {
      const rules = [(this.changePwForm.password === this.changePwForm.repPassword) || 'Lösenord matchar inte.']
      return rules
    },
    score: function () {
      const result = zxcvbn(this.changePwForm.password)
      switch (result.score) {
        case 4:
          return {
            color: 'light-green',
            value: 100
          }
        case 3:
          return {
            color: 'light-blue',
            value: 75
          }
        case 2:
          return {
            color: 'yellow',
            value: 50
          }
        case 1:
          return {
            color: 'orange',
            value: 25
          }
        default:
          return {
            color: 'red',
            value: 0
          }
      }
    },
    changePw: function () {
      return this.$store.getters.StateForcePwChange
    },
    otpDeviceConfirmed: function () {
      return this.$store.getters.StateOtpDeviceConfirmed
    },
    otpDeviceType: function () {
      return this.$store.getters.StateOtpDeviceType
    },
    otpUrl: function () {
      return this.$store.getters.StateOtpUrl
    },
    otpValidator: function () {
      const rules = []
      if (this.validCode) {
        const rule = true
        rules.push(rule)
      } else {
        const rule = v => {
          if ((v || '').length <= 0) {
            this.validCode = true
          }
          return (v || '').length <= 0 || 'Fel kod'
        }
        rules.push(rule)
      }
      return rules
    }
  },
  created () {
    window.localStorage.clear()
    this.CSRFToken()
  },
  methods: {
    ...mapActions(['LogIn', 'TempLogin', 'CSRFToken', 'ChangePassWord']),
    async submitLogin () {
      this.isSubmitted = true
      const User = new FormData()
      User.append('email', this.form.email)
      User.append('password', this.form.password)
      try {
        await this.TempLogin(User)
        this.isSubmitted = false
        // setTimeout(() => console.log(this.$store.getters.tempToken), 2000)
      } catch (error) {
        this.showError = true
        this.snackbarText = 'Inloggning misslyckades'
        this.isSubmitted = false
        this.form.email = ''
        this.form.password = ''
      }
    },
    async submitCode () {
      this.isSubmitted = true
      const params = {
        code: this.code,
        deviceType: this.otpDeviceType
      }
      try {
        await this.LogIn(params)
      } catch (e) {
        this.showError = true
        this.snackbarText = 'Fel kod'
        this.validCode = false
        this.code = null
      }
      this.isSubmitted = false
      await this.$router.push('/')
    },
    async submitNewPassword () {
      this.isSubmitted = true
      try {
        await this.ChangePassWord(this.changePwForm.password)
      } catch (e) {
        this.showError = true
        if (e.response.status === 400) {
          this.snackbarText = 'Ditt nya lösenord kan inte vara samma som det förra'
          this.changePwForm.password = ''
          this.changePwForm.repPassword = ''
        } else {
          this.snackbarText = 'Kunde inte uppdatera nytt lösenord'
        }
      }
      this.isSubmitted = false
    },
    goRegister () {
      this.$router.push('/account')
    },
    sendToggleMessageToParent (msg) {
      this.$emit('messageFromChild', msg)
    }
  }
}
</script>
