<template>
  <v-card width="1200" class="mx-auto" elevation="0">
    <v-data-table
      v-model="selected"
      :headers="headers"
      :items="Alerts"
      class="elevation-1"
      :loading="isLoading"
      show-select
      item-key="id"
      loading-text="Hämtar Alerts..."
      :search="search"
      :footer-props=" {
        showFirstLastPage: true,
        'items-per-page-text': 'Alerts per sida'
      }"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>Alerts</v-toolbar-title>
          <v-menu left bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on">
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>
            <v-list flat dense>
              <v-subheader>RENSA</v-subheader>
              <v-list-item @click="markAsRead()" :disabled="selected <= 0">
                <v-list-item-icon>
                <v-icon>mdi-bell-remove-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Ta bort markerade</v-list-item-title>
              </v-list-item-content>
              </v-list-item>
              <v-subheader>VISA</v-subheader>
              <v-list-item @click="showInfo()" :disabled="search === 'displayInfo'">
                <v-list-item-icon>
                <v-icon color="blue">mdi-information-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Enbart Info</v-list-item-title>
              </v-list-item-content>
              </v-list-item>
              <v-list-item @click="showWarning()" :disabled="search === 'displayWarning'">
                <v-list-item-icon>
                <v-icon color="secondary">mdi-alert-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Enbart Varning</v-list-item-title>
              </v-list-item-content>
              </v-list-item>
              <v-list-item @click="showAll()" :disabled="search === ''">
                <v-list-item-icon>
                <v-icon>mdi-dots-horizontal-circle-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Alla</v-list-item-title>
              </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-toolbar>
      </template>
      <template v-slot:item.msg="{item}">
        {{ getCatDescription(item.alert_cat)}}
      </template>
      <template v-slot:item.datetime_created="{item}">
        <BaseDateTime :time-stamp="item.datetime_created"/>
      </template>
      <template v-slot:item.displayCat="{item}">
        <v-icon :color="getCatIconColor(item.displayCat)">{{ getCatIcon(item.displayCat) }}</v-icon>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
export default {
  name: 'AlertsListTable',
  data: () => ({
    isLoading: false,
    selected: [],
    search: ''
  }),
  computed: {
    ...mapGetters({ Alerts: 'StateAlerts' }),
    companyId: function () {
      return this.$store.getters.StateSelectedOrgNumber
    },
    headers: function () {
      return [
        { text: 'Cat', value: 'displayCat' },
        { text: 'Text', value: 'msg' },
        { text: 'Inkom', value: 'datetime_created' }
      ]
    }
  },
  created: function () {
    this.unwatchRetrieveAlerts = this.$store.watch(
      (state, getters) => getters.StateAlerts,
      () => {
        this.isLoading = false
      }
    )
  },
  methods: {
    ...mapActions(['RetrieveAlerts', 'UpdateAlert', 'SpecificUpdateAlert']),
    markAsRead () {
      this.selected.forEach(element => {
        try {
          const data = { id: element.id, companyId: this.companyId }
          this.SpecificUpdateAlert(data)
        } catch (e) {
          throw new Error(e)
        }
      })
    },
    getCatDescription (item) {
      if (item === 'MTU') return 'Aktivering avslutad av företag'
      if (item === 'CTU') return 'Certifiering borttagen av företagare'
      if (item === 'CCR') return 'Certifiering initierad av företagare'
      if (item === 'CEX') return 'Certifiering har gått ut'
      if (item === 'UTM') return 'Aktivering avbruten'
      if (item === 'MAC') return 'Certifiering aktiverad'
      if (item === 'CDC') return 'Certifiering avbruten av system'
    },
    getCatIcon (item) {
      if (item === 'displayInfo') return 'mdi-information-outline'
      else return 'mdi-alert-outline'
    },
    getCatIconColor (item) {
      if (item === 'displayInfo') return 'blue'
      else return 'secondary'
    },
    showInfo () {
      this.search = 'displayInfo'
    },
    showWarning () {
      this.search = 'displayWarning'
    },
    showAll () {
      this.search = ''
    }
  },
  beforeDestroy () {
    this.$store.commit('setAlerts', [])
    this.unwatchRetrieveAlerts()
  }
}
</script>
