<template>
  <div>
    <v-card width="1200" class="mx-auto mt-5">
    <v-app-bar elevation="0">
      <v-menu bottom left>
        <template v-slot:activator="{on, attrs}">
          <v-app-bar-nav-icon v-bind="attrs" v-on="on">
          </v-app-bar-nav-icon>
        </template>
        <v-list flat dense>
          <v-subheader>AKTIVERING</v-subheader>
            <v-list-item v-for="(item, index) in items"
                         :key="index"
                         @click="menuActionClick(item.action)"
                         :disabled="lockSelection(index)">
              <v-list-item-icon>
                <v-icon v-text="item.icon"></v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          <v-subheader>KONTAKT</v-subheader>
          <v-list-item @click="addEmail">
            <v-list-item-icon>
              <v-icon>mdi-email</v-icon>
            </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>
                  Lägg till e-post
                </v-list-item-title>
              </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>

      <v-toolbar-title>{{ companyTitle }}</v-toolbar-title>
      <v-spacer></v-spacer>

      <span v-if="isRequested"><BaseDateTime :time-stamp="this.MonitoredCompany.date_modified"/></span>
      <span v-if="isMonitoring"><BaseDateTime :time-stamp="this.MonitoredCompany.date_monitor_started"/></span>
      <span v-if="isUserTerminated"><BaseDateTime :time-stamp="this.MonitoredCompany.date_monitor_ended"/></span>
      <span v-if="isCreditorTerminated"><BaseDateTime :time-stamp="this.MonitoredCompany.date_monitor_ended"/></span>
      <span v-if="isDeclined">Nekad: <BaseDateTime :time-stamp="this.MonitoredCompany.date_modified"/></span>
      <v-chip v-if="isRequested" class="ma-2" color="black" dark>Begärd</v-chip>
      <v-chip v-if="isMonitoring" class="ma-2" color="primary">Aktiverad</v-chip>
      <v-chip v-if="isDeclined" class="ma-2" color="secondary">Nekad</v-chip>
      <v-chip v-if="isUserTerminated" class="ma-2" color="secondary">Avbruten</v-chip>
      <v-chip v-if="isCreditorTerminated" class="ma-2" color="secondary">Avslutad</v-chip>
    </v-app-bar>
    <v-row>
      <v-col cols="12" class="col-md-4 col-lg-4">
        <v-card-subtitle><strong>SNI: </strong>{{ sniDescription }}
          <div><strong>Org. nummer</strong> {{ orgNumberDisplay }}</div>
          <div><strong>Registrerat: </strong>{{ SelectedCompany.company_registration_date }}</div>
        </v-card-subtitle>
      </v-col>
      <v-col cols="12" class="col-md-4 col-lg-4">
        <v-card-subtitle>
          <strong>Gatuadress: </strong>{{ SelectedCompany.street }}
          <div><strong>Ort: </strong>{{ cityDisplay }}</div>
          <div class="font-weight-normal"><strong>E-post: </strong>{{ companyEmail }}
            <v-btn x-small
                   class="gray ml-4"
                   dark
                   @click="addEmail">Uppdatera</v-btn></div>
          <div class="font-weight-normal"><strong>Telefon: </strong>{{ companyPhoneNumber }}</div>
        </v-card-subtitle>
      </v-col>
      <v-col cols="12" class="col-md-4 col-lg-4">
        <v-card-subtitle><strong>F-Skatt: </strong><TheIconCheck :status="this.SelectedCompany.f_tax" />
          <div><strong>Arb. Givar Avgift: </strong><TheIconCheck :status="this.SelectedCompany.payroll_tax" /></div>
          <div><strong>Moms reg: </strong><TheIconCheck :status="this.SelectedCompany.vat_registration" /></div>
        </v-card-subtitle>
      </v-col>
    </v-row>
    <v-divider class="mx-4"></v-divider>
    <v-row>
      <v-col cols="12" class="col-md-4 col-lg-4">
        <v-card-title>Styrelse</v-card-title>
          <v-card-text>
            <v-list dense>
              <v-list-item v-for="(item, index) in this.SelectedCompany.board_set" :key="index">
                <v-list-item-icon class="mx-0">
                  <v-icon small>mdi-account</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{ item.name }}</v-list-item-title>
                  <v-list-item-subtitle>{{ maskedPersonalNumber(item.personal_number) }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card-text>
      </v-col>
      <v-col cols="12" class="col-md-4 col-lg-4">
      <v-card-title>Ekonomisk översikt</v-card-title>
        <v-card-subtitle>KSEK</v-card-subtitle>
        <v-card-text>
          <div class="font-weight-normal"><strong>Bokslutsår start: </strong>{{ financials.start_period }}</div>
          <div class="font-weight-normal"><strong>Bokslutsår slut: </strong>{{ financials.end_period }}</div>
          <div class="font-weight-normal"><strong>Omsättning: </strong>{{ financials.revenue.toLocaleString() }}</div>
          <div class="font-weight-normal"><strong>EBIT: </strong>{{ financials.ebit.toLocaleString() }}</div>
          <div class="font-weight-normal"><strong>Årets Resultat: </strong>{{ financials.net_profit.toLocaleString() }} </div>
        </v-card-text>
      </v-col>
      <v-col cols="12" class="col-md-4 col-lg-4">
        <v-card-title>Övrigt</v-card-title>
        <v-card-text>
          <div class="font-weight-normal"><strong>Antal anställda: </strong>{{ financials.employee_count }}</div>
          <div class="font-weight-normal"><strong>Kassalikviditet: </strong>{{ financials.liquidity }}</div>
          <div class="font-weight-normal"><strong>Soliditet: </strong>{{ financials.solidity }}</div>
          <div class="font-weight-normal"><strong>Anmärkningar: </strong>{{ remarks }}</div>
          <div class="font-weight-normal"><strong>Skuldsaldo: </strong>{{ SelectedCompany.debt }}</div>
        </v-card-text>
      </v-col>
    </v-row>
  </v-card>
    <v-dialog v-model="emailDialog" width="600px">
      <CompanyContactDialog :email="companyEmail"
                            :phone="companyPhoneNumber"
                            :companyId="companyId"
                            :creditorId="creditorId"
                            @closeDialog="emailDialog = false"></CompanyContactDialog>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import CompanyContactDialog from './CompanyContactDialog'
import TheIconCheck from './TheIconCheck'

export default {
  name: 'Company',
  components: { TheIconCheck, CompanyContactDialog },
  data: () => ({
    companyName: null,
    checked: false,
    selectedItem: 1,
    emailDialog: false,
    items: [
      {
        title: 'Aktivera',
        icon: 'mdi-eye',
        action: 'requestMonitoring'
      },
      {
        title: 'Inaktivera',
        icon: 'mdi-eye-off',
        action: 'stopMonitoring'
      }
    ]
  }),
  computed: {
    ...mapGetters({ SelectedCompany: 'StateCompany', MonitoredCompany: 'StateMonitoredObject', CompanyContact: 'StateCompanyContact' }),

    companyTitle: function () {
      return this.SelectedCompany.company_name
    },
    sniDescription: function () {
      if (this.SelectedCompany.sni_code) {
        return this.SelectedCompany.sni_code.sni_code + ' - ' + this.SelectedCompany.sni_code.sni_code_description
      } else {
        return 'SNI-kod saknas'
      }
    },
    financials: function () {
      if (this.SelectedCompany.finance_set.length > 0) return this.SelectedCompany.finance_set[0]
      else {
        const obj = {
          start_period: '-',
          end_period: '-',
          revenue: '-',
          ebit: '-',
          net_profit: '-',
          employee_count: '-'
        }
        return obj
      }
    },
    remarks: function () {
      if (this.SelectedCompany.payment_remarks) return this.SelectedCompany.payment_remarks.length
      else return 0
    },
    orgNumberDisplay: function () {
      return this.SelectedCompany.org_number
    },
    companyEmail: function () {
      if (this.CompanyContact && this.CompanyContact.length > 0) {
        return this.CompanyContact[0].email
      } else {
        return ''
      }
    },
    companyPhoneNumber: function () {
      if (this.CompanyContact && this.CompanyContact.length > 0) {
        return this.CompanyContact[0].phone_number
      } else {
        return ''
      }
    },
    cityDisplay: function () {
      return this.SelectedCompany.zip_code + ' ' + this.SelectedCompany.city
    },
    score: function () {
      if (this.SelectedCompany.score) { return this.SelectedCompany.score.score } else return 'Saknas'
    },

    orgNumber: function () {
      return this.SelectedCompany.org_number
    },
    companyId: function () {
      return this.SelectedCompany.id
    },
    creditorId: function () {
      return this.$store.getters.StateUser.creditorId
    },
    isMonitoring: function () {
      if (this.MonitoredCompany) return this.MonitoredCompany.cert_status === 'A'
      else return false
    },
    isRequested: function () {
      if (this.MonitoredCompany) return this.MonitoredCompany.cert_status === 'R'
      else return false
    },
    isDeclined: function () {
      if (this.MonitoredCompany) return this.MonitoredCompany.cert_status === 'D'
      else return false
    },
    isCreditorTerminated () {
      if (this.MonitoredCompany) return this.MonitoredCompany.cert_status === 'C'
      else return false
    },
    isUserTerminated () {
      if (this.MonitoredCompany) return this.MonitoredCompany.cert_status === 'U'
      else return false
    },
    lockedMenuItems () {
      // Calculate Menu items to be disabled
      const arr = []
      if (this.isMonitoring || this.isRequested) arr.push(0)
      else if (this.isDeclined || this.isCreditorTerminated || this.isUserTerminated) arr.push(1)
      else arr.push(1)
      return arr
    }
  },
  created () {
    // const monitoredCompany = this.$store.getters.filteredBy(this.$store.getters.StateSelectedOrgNumber)[0]
    // Using local monitorObject if the monitor list does get very large and paginated
    /* if (monitoredCompany !== null) {
      this.$store.commit('setMonitoredObject', monitoredCompany)
    } */
    this.RetrieveCompanyContact(this.$store.getters.StateSelectedCompanyId)
  },
  methods: {
    ...mapActions(['CreateMonitoringForCompany', 'UpdateMonitoringStatusForCompany', 'RetrieveCompanyContact']),
    menuActionClick (action) {
      if (action === 'requestMonitoring') {
        this.requestMonitoring()
      } else if (action === 'stopMonitoring') {
        this.stopMonitoring()
      }
    },
    lockSelection (item) {
      if (this.lockedMenuItems.includes(item)) {
        return true
      }
    },
    requestMonitoring () {
      if (this.MonitoredCompany) {
        // We already have a monitor record so just updated the status to 'R'
        this.refreshMonitoring()
      } else {
        const clientId = this.$store.getters.StateUser.client_id
        const data = { companyId: this.companyId, creditorId: this.creditorId, userId: clientId }
        try {
          this.CreateMonitoringForCompany(data)
        } catch (error) {
          throw Error(error)
        }
      }
    },
    stopMonitoring () {
      const data = { companyId: this.companyId, creditorId: this.creditorId, status: 'C', id: this.MonitoredCompany.id }
      try {
        this.UpdateMonitoringStatusForCompany(data)
      } catch (error) {
        throw Error(error)
      }
    },
    refreshMonitoring () {
      const data = { companyId: this.companyId, creditorId: this.creditorId, status: 'R', id: this.MonitoredCompany.id }
      try {
        this.UpdateMonitoringStatusForCompany(data)
      } catch (error) {
        throw Error(error)
      }
    },
    addEmail () {
      this.emailDialog = true
    },
    maskedPersonalNumber (item) {
      let full = item.toString()
      full = full.substring(0, 8)
      return (full += '-XXXX')
    }
  },
  beforeDestroy () {
    this.$store.commit('setCompanyContact', null)
    this.$store.commit('setMonitoredObject', {})
  }
}
</script>
