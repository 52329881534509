<template>
<v-row class="mt-16">
      <v-col>
      <PasswordReset></PasswordReset>
      </v-col>
    </v-row>
</template>

<script>
import PasswordReset from '../components/PasswordReset'
export default {
  name: 'PageResetPassword',
  components: { PasswordReset }
}
</script>

<style scoped>

</style>
