<template>
  <v-container>
    <v-row class="text-center">
      <v-col>
      <h1>Du har blivit utloggad</h1>
        <router-link to="login">Logga in igen</router-link>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'PageLoggedOut'
}
</script>
