<template>
  <v-container>
    <h1>Alerts</h1>
    <AlertsListTable/>
  </v-container>
</template>

<script>
import AlertsListTable from '@/components/AlertsDataIterator'
export default {
  name: 'PageAlerts',
  components: { AlertsListTable }
}
</script>
