import axios from 'axios'

const state = {
  alerts: []
}
const getters = {
  StateAlerts: (state) => state.alerts
}
const actions = {
  async ListAlerts ({ commit }) {
    const myPath = '/api/alerts/'
    await axios.get(myPath).then(response => {
      commit('setAlerts', response.data)
    })
  },
  async UpdateAlert ({ dispatch }, id) {
    const myPath = '/api/alerts/' + id + '/'
    const MyFormData = new FormData()
    MyFormData.append('read', 'true')
    await axios.patch(myPath, MyFormData)
    dispatch('ListAlerts')
  },
  async RetrieveAlerts ({ commit }, companyId) {
    const myPath = '/api/alerts/' + companyId + '/'
    await axios.get(myPath).then(response => {
      commit('setAlerts', response.data)
    })
  },
  async SpecificUpdateAlert ({ dispatch }, data) {
    const myPath = '/api/alerts/' + data.id + '/'
    const MyFormData = new FormData()
    MyFormData.append('read', 'true')
    await axios.patch(myPath, MyFormData)
    dispatch('RetrieveAlerts', data.companyId)
  }
}

const mutations = {
  setAlerts (state, alerts) {
    if (alerts !== null) {
      alerts.forEach(function (element) {
        if (element.alert_cat === 'CCR' || element.alert_cat === 'MAC') {
          element.displayCat = 'displayInfo'
        } else {
          element.displayCat = 'displayWarning'
        }
      })
    }
    state.alerts = alerts
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
