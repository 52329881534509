<template>
  <div class="small">
    <BarChart :chart-data="datacollection" :options="barOptions"></BarChart>
  </div>
</template>

<script>
import BarChart from './BarChart'
import { mapGetters, mapActions } from 'vuex'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import localizedTime from 'dayjs/plugin/localizedFormat'
import 'dayjs/locale/sv'
dayjs.locale('sv')
dayjs.extend(relativeTime)
dayjs.extend(localizedTime)
export default {
  name: 'AlertHistoryChart',
  components: { BarChart },
  data: () => ({
    datacollection: {},
    barOptions: {
      legend: { display: false },
      title: {
        display: false,
        text: 'Antal notiser'
      },
      scales: {
        yAxes: [{
          ticks: {
            beginAtZero: true
          }
        }]
      }
    }
  }),
  computed: {
    ...mapGetters({ AlertHistory: 'StateHistoryAlerts' }),
    creditor: function () {
      return this.$store.getters.StateUser.creditorId
    },
    historyLabels: function () {
      if (this.AlertHistory == null) return []
      const t = this.AlertHistory.map(d => this.labelDescription(d.datetime_created__month, d.datetime_created__year))
      return t
    },
    historyValues: function () {
      if (this.AlertHistory == null) return []
      const t = this.AlertHistory.map(d => d.count)
      return t
    }
  },
  created () {
    try {
      this.FetchHistoryAlerts(this.creditor)
    } catch (e) {
      throw new Error(e)
    }
    this.unwatchHistoryAlerts = this.$store.watch(
      (state, getters) => getters.StateHistoryAlerts,
      () => {
        this.fillData()
      }
    )
  },
  mounted () {
    this.fillData()
  },
  methods: {
    ...mapActions(['FetchHistoryAlerts']),
    labelDescription (month, year) {
      const d = new Date(year, month - 1, 1)
      const djs = dayjs(d).format('MMM-YY')
      return djs
    },
    getRandomColors (count) {
      const y = []
      for (let x = 0; x < count; x++) {
        const color = '#' + (Math.random().toString(16) + '0000000').slice(2, 8)
        y.push(color)
      }
      return y
    },
    fillData () {
      this.datacollection = {
        labels: this.historyLabels,
        datasets: [
          {
            label: 'Antal Notiser',
            backgroundColor: this.getRandomColors(this.historyLabels.length),
            data: this.historyValues,
            fill: false
          }
        ]
      }
    }
  },
  beforeDestroy () {
    this.unwatchHistoryAlerts()
  }
}
</script>
