// store/modules/auth.js

import axios from 'axios'

const state = {
  user: null,
  jwtAccessToken: null,
  forcePwChange: false,
  otpUrl: null,
  otpDeviceConfirmed: false,
  otpDeviceType: null
}
const getters = {
  isAuthenticated: (state) => !!state.user,
  StateUser: (state) => state.user,
  StateJWTAccessToken: (state) => state.jwtAccessToken,
  StateForcePwChange: (state) => state.forcePwChange,
  StateOtpUrl: (state) => state.otpUrl,
  StateOtpDeviceConfirmed: (state) => state.otpDeviceConfirmed,
  StateOtpDeviceType: (state) => state.otpDeviceType
}
const actions = {
  async CSRFToken () {
    await axios.get('users/login-set-cookie/')
  },
  async LogIn ({ commit }, params) {
    const authToken = 'Bearer ' + this._vm.StateJWTAccessToken
    const formData = new FormData()
    formData.append('otpDeviceType', params.deviceType)
    await axios.post('users/totp/login/' + params.code, formData, { headers: { Authorization: authToken } }).then(response => {
      if (response.status === 200) {
        const user = {
          client_id: response.data.user.client_id,
          email: response.data.user.email,
          company: response.data.user.company,
          orgNumber: response.data.user.orgNumber,
          creditorId: response.data.user.creditorId,
          firstName: response.data.user.first_name,
          lastName: response.data.user.last_name
        }
        commit('setUser', user)
        commit('setOtpUrl', null)
        commit('setJWTAccessToken', null)
      }
    }).catch(err => {
      throw new Error(`${err.config.url} not found`)
    })
  },
  async TempLogin ({ commit, dispatch }, user) {
    await axios.post('users/creditor/temptoken/', user).then(response => {
      if (response.data.tokens) {
        commit('setJWTAccessToken', response.data.tokens.access)
        commit('setForcePwChange', response.data.force_password_change)
        if (!response.data.force_password_change) {
          return dispatch('TOTPCreateDevice')
        }
      }
    })
  },
  async TOTPCreateDevice ({ commit }) {
    const authToken = 'Bearer ' + this._vm.StateJWTAccessToken
    await axios.get('users/totp/create/', { headers: { Authorization: authToken } }).then(response => {
      if (response.status === 201) {
        commit('setOtpUrl', response.data.url)
        commit('setOtpDeviceConfirmed', response.data.confirmed)
        commit('setOtpDeviceType', response.data.otpDeviceType)
      }
    }).catch(err => {
      throw new Error(`${err.config.url} cannot send SMS`)
    })
  },
  async ChangePassWord ({ commit, dispatch }, password) {
    const authToken = 'Bearer ' + this._vm.StateJWTAccessToken
    const formData = new FormData()
    formData.append('pw', password)
    await axios.post('users/changepw/', formData, { headers: { Authorization: authToken } }).then(response => {
      if (response.status === 201) {
        commit('setForcePwChange', false)
        return dispatch('TOTPCreateDevice')
      }
    })
  },
  async LogOutFromServer ({ commit }) {
    await axios.post('/users/logout/').then(response => {
      if (response.status === 200) {
        const user = null
        commit('logout', user)
      }
    }).catch(err => {
      throw new Error(`${err.config.url} not found`)
    })
  }
}

const mutations = {
  setUser (state, email) {
    state.user = email
  },
  setJWTAccessToken (state, token) {
    state.jwtAccessToken = token
  },
  setOtpUrl (state, url) {
    state.otpUrl = url
  },
  setOtpDeviceConfirmed (state, confirmed) {
    state.otpDeviceConfirmed = confirmed
  },
  setOtpDeviceType (state, deviceType) {
    state.otpDeviceType = deviceType
  },
  setForcePwChange (state, change) {
    state.forcePwChange = change
  },
  logout (state) {
    state.user = null
    state.jwtAccessToken = null
    state.otpUrl = null
    state.otpDeviceConfirmed = false
    window.localStorage.clear()
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
