<template>
  <div>
  <v-alert v-if="showBankRenewalAlert"
      color="secondary"
      dark
      icon="mdi-alert"
      border="left"
      prominent
    >
    {{ this.renewalText }}
    </v-alert>
  <v-card width="1200" class="mx-auto mt-5" elevation="0">
    <v-data-table
        :headers="headers"
        :items="Connections"
        :sortDesc=true
        class="elevation-1"
        hide-default-footer
        :loading="isLoading"
        :single-expand="true"
        show-expand
    >
      <template v-slot:expanded-item="{ headers, item }">
      <td :colspan="headers.length">
        Updated by {{ item.initiator }}
      </td>
    </template>
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>Bank</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <span class="text-left">90 Dagars Cert.</span>
          <v-spacer></v-spacer>
        </v-toolbar>
      </template>
      <template v-slot:item.bankLogo="{ item }">
        <v-img max-height="40"
               max-width="60"
               :src="getBankLogoFromBic(item)">
        </v-img>
      </template>
      <template v-slot:item.status="{ item }">
        <v-icon v-if="item.status === 'C'" :color="displayColor(item.status)">mdi-check-decagram</v-icon>
        <v-icon v-if="item.status === 'D'" :color="displayColor(item.status)">mdi-cancel</v-icon>
        <v-icon v-if="item.status === 'N'" :color="displayColor(item.status)">mdi-alert-circle</v-icon>
      </template>
      <template v-slot:item.since="{ item }">
        <tr v-if="item.consent">
          <BaseDateTime :time-stamp="item.consent.datetime_created"/>
        </tr>
      </template>
      <template v-slot:item.account_status="{ item }">
        {{ displayAccountStatus(item.account_status)}}
      </template>
      <template v-slot:item.in_progress="{ item }">
        <v-progress-circular v-if="item.in_progress"
            indeterminate
            color="primary"
        ></v-progress-circular>
      </template>
      <template v-slot:item.validUntil="{ item }">
        {{ displayValidUntil(item) }}
      </template>
      <template v-slot:item.daysLeft="{ item }">
        {{ displayDaysLeftForBankCon(item)}}
      </template>
      <template v-slot:item.serverType="{ item }">
        <v-chip
            label
            :color="displayServerColor(item.production_server)"
            class="ma-2"
            outlined
            dark
            small
        >
          <v-icon small left>mdi-server</v-icon>
          {{ displayServerDescription(item.production_server) }}
        </v-chip>
      </template>
    </v-data-table>
  </v-card>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
export default {
  name: 'BankAccess',
  props: ['tokenCode'],
  data: () => ({
    isLoading: true,
    isSubmitted: false,
    isFetchingAccounts: false,

    showBankRenewalAlert: false,
    renewalText: null,
    daysRenewalAlertShow: 10,

    expanded: [],
    headers: [
      { text: 'Bank', align: 'start', sortable: false, value: 'bankLogo' },
      { text: 'Status', value: 'status', sortable: false },
      { text: 'Sedan', value: 'since', sortable: false },
      { text: 'Giltig tom.', value: 'validUntil', sortable: false },
      { text: 'Löper ut', value: 'daysLeft', sortable: false },
      { text: '', value: 'in_progress', sortable: false }
    ]
  }),
  computed: {
    ...mapGetters({ Connections: 'StateConnections', Banks: 'StateAspspList', CompanyId: 'StateSelectedCompanyId' }),

    connectTitle () {
      return this.getBankNameFromBic(this.editedItem.bicfi)
    },
    user: function () {
      return this.$store.getters.StateUser
    }
  },

  created: function () {
    try {
      this.ListConnections(this.CompanyId)
    } catch (e) {
      throw new Error(e)
    }
    this.unwatchConnections = this.$store.watch(
      (state, getters) => getters.StateConnections,
      () => {
        this.isLoading = false
      }
    )
  },
  methods: {
    ...mapActions(['ListConnections']),
    isConnected (item) {
      if (item.consent) {
        if (item.consent.sca_status === 'finalised' && item.consent.consent_status === 'valid') return true
      } else {
        return false
      }
    },
    displayColor (status) {
      if (status === 'D') return 'red'
      if (status === 'C') return 'green'
    },
    displayServerColor (server) {
      if (server) return 'green'
      else return 'red'
    },
    displayValidUntil (item) {
      if ('consent' in item && item.consent != null && item.consent.date_valid_until != null && item.status === 'C') {
        return item.consent.date_valid_until
      }
    },
    displayServerDescription (server) {
      if (server) return 'Produktion'
      else return 'Sandbox'
    },
    displayDaysLeftForBankCon (item) {
      if ('consent' in item && item.consent != null && item.consent.date_valid_until != null && item.status === 'C') {
        const today = new Date()
        const itemDate = new Date(item.consent.date_valid_until)
        const diffTime = itemDate - today
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24))
        if (diffDays < this.daysRenewalAlertShow && diffDays > 0) {
          this.showBankRenewalAlert = true
          this.renewalText = 'Certifiering går ut om ' + diffDays + ' dagar!'
        } else if (diffDays < 0) {
          this.showBankRenewalAlert = true
          this.renewalText = 'Certifiering har gått ut, vänligen förnya'
        } else if (diffDays === 0) {
          this.showBankRenewalAlert = true
          this.renewalText = 'Certifiering går ut idag, vänligen förnya'
        }
        return diffDays + ' dagar'
      } else return ''
    },
    getBankLogoFromBic (item) {
      if (this.Banks == null) return 'NA'
      const bic = this.Banks.filter(element => element.bicFi === item.bicfi)
      return bic[0].logoUrl
    },
    getBankNameFromBic (bifci) {
      if (this.Banks == null) return 'NA'
      const bic = this.Banks.filter(element => element.bicFi === bifci)
      return bic[0].name
    },

    displayAccountStatus (item) {
      if (item === 'NA') return ''
      else if (item === 'FA') return 'Hämtar konto info...'
    }
  },
  beforeDestroy () {
    this.$store.commit('setConnections', [])
    this.unwatchConnections()
  }
}
</script>
