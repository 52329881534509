<template>
  <v-container fluid>
    <v-data-iterator
      :items="convertedAlerts"
      hide-default-footer
      :items-per-page.sync="itemsPerPage"
      :page.sync="page"
      :search="search"
      :loading="true"
      loading-text="Hämtar alerts..."
    >
      <template v-slot:no-data>
        <v-alert type="info" outlined>
          Inga alerts
        </v-alert>
      </template>
      <template v-slot:header>
        <v-toolbar dark color="primary" class="mb-4">
          <v-text-field
            v-model="search"
            clearable
            flat
            solo-inverted
            hide-details
            prepend-inner-icon="mdi-magnify"
            label="Sök alerts"
          ></v-text-field>
          <v-spacer></v-spacer>
          <span class="mr-3">Visa:</span>
          <v-btn-toggle v-model="toggle_exclusive">
            <v-btn color="primary">
              <v-icon>mdi-information-outline</v-icon>
            </v-btn>
            <v-btn color="primary">
              <v-icon>mdi-alert-outline</v-icon>
            </v-btn>
          </v-btn-toggle>
        </v-toolbar>
      </template>
      <template v-slot:default="props">
        <v-row>
          <v-col v-for="item in props.items" :key="item.id" cols="12">
            <v-alert
                class="mx-4"
                :type=displayType(item.kategori)
                border="right"
                dismissible
                colored-border
                elevation="2"
                @input="closeAlert(item)"
            >
              <BaseDateTime :time-stamp="item['tid']"/>
              <v-list dense>
                <v-list-item v-for="(key, index) in filteredKeys" :key="index">
                  <v-list-item-content :class="{ 'blue--text': sortBy === key }">
                    <strong>{{ key }}:</strong>
                  </v-list-item-content>
                  <v-list-item-content class="align-end" :class="{ 'blue--text': sortBy === key }">
                    <div v-if="key.toLowerCase() === 'kategori'">
                      {{ getCatDescription(item[key.toLowerCase()]) }}
                    </div>
                    <div v-else>
                      {{ item[key.toLowerCase()] }}
                    </div>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-alert>
          </v-col>
        </v-row>
      </template>
      <template v-slot:footer>
        <v-row class="mt-2" align="center" justify="center">
          <span class="grey--text">Alerts per sida</span>
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                dark
                text
                color="primary"
                class="ml-2"
                v-bind="attrs"
                v-on="on"
              >
                {{ itemsPerPage }}
                <v-icon>mdi-chevron-down</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item v-for="(number, index) in itemsPerPageArray" :key="index" @click="updateItemsPerPage(number)">
                <v-list-item-title>{{ number }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
          <v-spacer></v-spacer>

          <span class="mr-4 grey--text">Sida {{ page }} av {{ numberOfPages }}</span>
          <v-btn fab dark color="primary" class="mr-1" @click="formerPage">
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
          <v-btn fab dark color="primary" class="ml-1" @click="nextPage">
            <v-icon>mdi-chevron-right</v-icon>
          </v-btn>
        </v-row>
      </template>
    </v-data-iterator>
  </v-container>

</template>

<script>
import { mapActions, mapGetters } from 'vuex'
export default {
  name: 'AlertsDataIterator',
  data: () => ({
    isLoading: false,
    itemsPerPageArray: [15, 30, 90],
    actionDialog: false,
    sortBy: 'datetime',
    sortDesc: false,
    page: 1,
    itemsPerPage: 15,
    search: '',
    toggle_exclusive: 2,
    filter: {},
    keys: [
      'id',
      'Företag',
      'OrgNummer',
      'Kategori',
      'tid'
    ]
  }),
  computed: {
    ...mapGetters({ Alerts: 'StateAlerts' }),
    numberOfPages () {
      return Math.ceil(this.convertedAlerts.length / this.itemsPerPage)
    },
    convertedAlerts: function () {
      const y = this.Alerts.map(obj => {
        return {
          företag: obj.company.company_name,
          orgnummer: obj.company.org_number,
          kategori: obj.alert_cat,
          id: obj.id,
          tid: obj.datetime_created
        }
      })
      return y
    },
    filteredKeys () {
      const excluded = ['id', 'tid']
      return this.keys.filter(key => !excluded.includes(key))
    },
    headers: function () {
      return [
        { text: ('Läst'), value: 'active', sortable: true, align: 'start' },
        {
          text: ('Bolagsnamn'),
          sortable: true,
          value: 'company.company_name'
        },
        { text: ('Meddelande'), value: 'cat', sortable: true },
        { text: ('Datum'), value: 'datetime', sortable: true }
      ]
    }
  },
  created () {
    this.isLoading = true
    try {
      this.ListAlerts()
    } catch (error) {
      this.isLoading = false
    }
    this.unwatchListAlerts = this.$store.watch(
      (state, getters) => getters.StateAlerts,
      () => {
        this.isLoading = false
      }
    )
  },
  methods: {
    ...mapActions(['ListAlerts', 'UpdateAlert']),
    nextPage () {
      if (this.page + 1 <= this.numberOfPages) this.page += 1
    },
    formerPage () {
      if (this.page - 1 >= 1) this.page -= 1
    },
    updateItemsPerPage (number) {
      this.itemsPerPage = number
    },
    getCatDescription (item) {
      if (item === 'MTU') return 'Aktivering avslutad av företag'
      if (item === 'CTU') return 'Certifiering borttagen av företagare'
      if (item === 'CCR') return 'Certifiering skapad av företagare'
      if (item === 'CEX') return 'Certifiering har gått ut'
      if (item === 'UTM') return 'Aktivering avbruten'
      if (item === 'MAC') return 'Aktivering godkänd'
      if (item === 'CDC') return 'Certifiering avbruten av system'
    },
    displayType (item) {
      if (item === 'MTU') return 'error'
      if (item === 'CTU') return 'error'
      if (item === 'CCR') return 'info'
      if (item === 'MAC') return 'info'
      if (item === 'CEX') return 'error'
      if (item === 'UTM') return 'error'
      if (item === 'CDC') return 'error'
    },
    closeAlert (item) {
      this.UpdateAlert(item.id)
    }
  },
  watch: {
    toggle_exclusive (val) {
      if (val === undefined) this.search = ''
      if (val === 0) this.search = 'CCR'
      if (val === 1) this.search = 'CTU'
    }
  },
  beforeDestroy () {
    this.unwatchListAlerts()
    this.$store.commit('setAlerts', [])
  }
}
</script>
