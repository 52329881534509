<template>
  <v-card class="mx-auto mt-7" max-width="600">
    <v-row>
      <v-col cols="6">
        <v-list-item two-line>
          <v-list-item-content>
            <v-list-item-title class="headline">
              Omsättning
            </v-list-item-title>
            <v-list-item-subtitle>Summa de senaste 31 dagarna</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-col>
      <v-col cols="6">
        <v-list-item two-line>
          <v-list-item-content>
            <v-list-item-title class="headline">
              Kostnader
            </v-list-item-title>
            <v-list-item-subtitle>Summa de senaste 31 dagarna</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-col>
    </v-row>

    <v-card-text>
      <v-row align="center">
        <v-col
          class="display-3"
          cols="6"
        >
          <span style="color: #29C475">{{ revenueComp }}</span>
        </v-col>
        <v-col
          class="display-3"
          cols="6"
        >
          <span style="color: #F46451">{{ costComp }}</span>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import axios from 'axios'
import { mapGetters } from 'vuex'
export default {
  name: 'AnalysisWidget',
  data: () => ({
    revenue: 0,
    cost: 0
  }),
  computed: {
    ...mapGetters({ CompanyId: 'StateSelectedCompanyId' }),
    revenueComp: function () {
      return Math.round(this.revenue).toLocaleString()
    },
    costComp: function () {
      return Math.round(this.cost).toLocaleString()
    }
  },
  created () {
    const myPath = '/analysis/chartrc/' + this.CompanyId
    axios.get(myPath).then(response => {
      const data = response.data
      this.revenue = data[0]
      this.cost = data[1]
    }).catch(error => {
      throw new Error(error)
    })
  }
}
</script>
