<template>
<v-navigation-drawer app>
    <v-img
        class="mt-4 ml-5"
        max-height="40"
        max-width="130"
        src="../assets/Credon_logo.svg"
        :to="'/'">
    </v-img>
  <v-list class="mt-5">
    <v-list-item v-for="item in items" :key="item.title" :to="item.link" link >
      <v-list-item-icon>
        <v-icon>{{ item.icon }}</v-icon>
      </v-list-item-icon>

      <v-list-item-content>
        <v-list-item-title>{{ item.title }}</v-list-item-title>
      </v-list-item-content>
    </v-list-item>
  </v-list>
    <template v-slot:append>
        <div class="pa-2">
          <v-btn block class="secondary" @click="logout">
            Logga ut
          </v-btn>
        </div>
      </template>
    <!-- -->
  </v-navigation-drawer>
</template>
<script>
import { mapActions } from 'vuex'
export default {
  name: 'NavigationDrawer',
  data () {
    return {
      items: [
        { title: 'Översikt', icon: 'mdi-view-dashboard', link: '/' },
        { title: 'Aktiva', icon: 'mdi-monitor-eye', link: '/monitoring' },
        { title: 'Alerts', icon: 'mdi-alert', link: '/alerts' },
        { title: 'Inställningar', icon: 'mdi-cog', link: '/conditions' }
      ]
    }
  },
  methods: {
    ...mapActions(['LogOutFromServer']),
    async logout () {
      await this.$store.dispatch('LogOutFromServer')
      await this.$router.push('/login')
    }
  }
}
</script>
