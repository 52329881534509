var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',[_c('v-col',{staticClass:"col-lg-6 col-md-6"},[_c('AnalysisWidget',{attrs:{"revenue":"32232","cost":"2111"}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"col-lg-6 col-md-6",attrs:{"cols":"12"}},[_c('v-card',{staticClass:"mx-auto",attrs:{"width":"600","elevation":"1"}},[_c('v-app-bar',{attrs:{"elevation":"0","color":"white"}},[(_vm.aaData)?_c('v-menu',{attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-app-bar-nav-icon',_vm._g(_vm._b({},'v-app-bar-nav-icon',attrs,false),on))]}}],null,false,508802045)},[_c('v-list',{attrs:{"flat":"","dense":""}},[_c('v-subheader',[_vm._v("HÄMTA SOM:")]),_c('v-list-item',[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-file-excel")])],1),_c('v-list-item-content',[_c('JsonExcel',{attrs:{"data":_vm.aaData,"fields":_vm.aaFields,"name":"accountactivity.xls"}},[_c('v-btn',{attrs:{"text":"","x-small":""}},[_vm._v("Excel")])],1)],1)],1),_c('v-list-item',[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-file-delimited")])],1),_c('v-list-item-content',[_c('JsonExcel',{attrs:{"data":_vm.aaData,"fields":_vm.aaFields,"name":"accountactivity.csv","type":"csv"}},[_c('v-btn',{attrs:{"text":"","x-small":""}},[_vm._v("CSV")])],1)],1)],1)],1)],1):_vm._e(),_c('v-spacer'),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-information")])],1)]}}]),model:{value:(_vm.show1),callback:function ($$v) {_vm.show1=$$v},expression:"show1"}},[_c('span',[_vm._v("Definition kontoaktivitet månad: Summan av intäkter och kostnad per månad")])])],1),_c('v-card-text',[_c('AccountActivityPerMonth')],1)],1)],1),_c('v-col',{staticClass:"col-lg-6 col-md-6",attrs:{"cols":"12"}},[_c('v-card',{staticClass:"mx-auto",attrs:{"width":"600","elevation":"1"}},[_c('v-app-bar',{attrs:{"elevation":"0","color":"white"}},[(_vm.bmdData)?_c('v-menu',{attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-app-bar-nav-icon',_vm._g(_vm._b({},'v-app-bar-nav-icon',attrs,false),on))]}}],null,false,508802045)},[_c('v-list',{attrs:{"flat":"","dense":""}},[_c('v-subheader',[_vm._v("HÄMTA SOM:")]),_c('v-list-item',[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-file-excel")])],1),_c('v-list-item-content',[_c('JsonExcel',{attrs:{"data":_vm.bmdData,"fields":_vm.bmdFields,"name":"balancedata.xls"}},[_c('v-btn',{attrs:{"text":"","x-small":""}},[_vm._v("Excel")])],1)],1)],1),_c('v-list-item',[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-file-delimited")])],1),_c('v-list-item-content',[_c('JsonExcel',{attrs:{"data":_vm.bmdData,"fields":_vm.bmdFields,"name":"balancedata.csv","type":"csv"}},[_c('v-btn',{attrs:{"text":"","x-small":""}},[_vm._v("CSV")])],1)],1)],1)],1)],1):_vm._e(),_c('v-spacer'),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-information")])],1)]}}]),model:{value:(_vm.show2),callback:function ($$v) {_vm.show2=$$v},expression:"show2"}},[_c('span',[_vm._v("Definition balans per månad: Minsta balans per månad")])])],1),_c('v-card-text',[_c('BalanceMonthDay')],1)],1)],1)],1),_c('v-row',[_c('v-col',{staticClass:"col-lg-6 col-md-6",attrs:{"cols":"12"}},[_c('v-card',{staticClass:"mx-auto",attrs:{"width":"600","elevation":"1"}},[_c('v-app-bar',{attrs:{"elevation":"0","color":"white"}},[(_vm.cfData)?_c('v-menu',{attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-app-bar-nav-icon',_vm._g(_vm._b({},'v-app-bar-nav-icon',attrs,false),on))]}}],null,false,508802045)},[_c('v-list',{attrs:{"flat":"","dense":""}},[_c('v-subheader',[_vm._v("HÄMTA SOM:")]),_c('v-list-item',[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-file-excel")])],1),_c('v-list-item-content',[_c('JsonExcel',{attrs:{"data":_vm.cfData,"fields":_vm.cfFields,"name":"cashflow.xls"}},[_c('v-btn',{attrs:{"text":"","x-small":""}},[_vm._v("Excel")])],1)],1)],1),_c('v-list-item',[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-file-delimited")])],1),_c('v-list-item-content',[_c('JsonExcel',{attrs:{"data":_vm.cfData,"fields":_vm.cfFields,"name":"cashflow.csv","type":"csv"}},[_c('v-btn',{attrs:{"text":"","x-small":""}},[_vm._v("CSV")])],1)],1)],1)],1)],1):_vm._e(),_c('v-spacer'),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-information")])],1)]}}])},[_c('span',[_vm._v("Definition kassaflöde: Summa intäkter och kostnader per månad")])])],1),_c('v-card-text',[_c('CashFlowChart')],1)],1)],1),_c('v-col',{staticClass:"col-lg-6 col-md-6",attrs:{"cols":"12"}},[_c('v-card',{staticClass:"mx-auto",attrs:{"width":"600","elevation":"1"}},[_c('v-app-bar',{attrs:{"elevation":"0","color":"white"}},[(_vm.txData)?_c('v-menu',{attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-app-bar-nav-icon',_vm._g(_vm._b({},'v-app-bar-nav-icon',attrs,false),on))]}}],null,false,508802045)},[_c('v-list',{attrs:{"flat":"","dense":""}},[_c('v-subheader',[_vm._v("HÄMTA SOM:")]),_c('v-list-item',[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-file-excel")])],1),_c('v-list-item-content',[_c('JsonExcel',{attrs:{"data":_vm.txData,"fields":_vm.txFields,"name":"taxdata.xls"}},[_c('v-btn',{attrs:{"text":"","x-small":""}},[_vm._v("Excel")])],1)],1)],1),_c('v-list-item',[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-file-delimited")])],1),_c('v-list-item-content',[_c('JsonExcel',{attrs:{"data":_vm.txData,"fields":_vm.txFields,"name":"taxdata.csv","type":"csv"}},[_c('v-btn',{attrs:{"text":"","x-small":""}},[_vm._v("CSV")])],1)],1)],1)],1)],1):_vm._e(),_c('v-spacer'),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-information")])],1)]}}])},[_c('span',[_vm._v("Definition skattetransaktioner: Summa in och utbetalningar till skattekonto")])])],1),_c('v-card-text',[_c('TaxPaymentsChart')],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }