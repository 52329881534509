<template>
<v-container>
  <v-row>
    <v-col class="col-lg-6 col-md-6">
      <AnalysisWidget revenue="32232"
                      cost="2111">
      </AnalysisWidget>
    </v-col>
  </v-row>
  <v-row>
    <v-col cols="12" class="col-lg-6 col-md-6">
      <v-card class="mx-auto" width="600" elevation="1">
        <v-app-bar elevation="0" color="white">
          <v-menu bottom left v-if="aaData">
            <template v-slot:activator="{on, attrs}">
              <v-app-bar-nav-icon v-bind="attrs" v-on="on">
              </v-app-bar-nav-icon>
            </template>
            <v-list flat dense>
              <v-subheader>HÄMTA SOM:</v-subheader>
                <v-list-item>
                  <v-list-item-icon>
                    <v-icon>mdi-file-excel</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <JsonExcel :data="aaData"
                               :fields="aaFields"
                               name="accountactivity.xls"
                               >
                      <v-btn text x-small>Excel</v-btn>
                    </JsonExcel>
                  </v-list-item-content>
                </v-list-item>
              <v-list-item>
                  <v-list-item-icon>
                    <v-icon>mdi-file-delimited</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <JsonExcel :data="aaData"
                               :fields="aaFields"
                               name="accountactivity.csv"
                               type="csv"
                               >
                      <v-btn text x-small>CSV</v-btn>
                    </JsonExcel>
                  </v-list-item-content>
                </v-list-item>
            </v-list>
          </v-menu>
          <v-spacer></v-spacer>
          <v-tooltip v-model="show1" top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on">
                <v-icon color="primary">mdi-information</v-icon>
              </v-btn>
            </template>
            <span>Definition kontoaktivitet månad: Summan av intäkter och kostnad per månad</span>
          </v-tooltip>
        </v-app-bar>
        <v-card-text>
          <AccountActivityPerMonth></AccountActivityPerMonth>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col cols="12" class="col-lg-6 col-md-6">
      <v-card class="mx-auto" width="600" elevation="1">
        <v-app-bar elevation="0" color="white">
          <v-menu bottom left v-if="bmdData">
            <template v-slot:activator="{on, attrs}">
              <v-app-bar-nav-icon v-bind="attrs" v-on="on">
              </v-app-bar-nav-icon>
            </template>
            <v-list flat dense>
              <v-subheader>HÄMTA SOM:</v-subheader>
                <v-list-item>
                  <v-list-item-icon>
                    <v-icon>mdi-file-excel</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <JsonExcel :data="bmdData"
                               :fields="bmdFields"
                               name="balancedata.xls"
                               >
                      <v-btn text x-small>Excel</v-btn>
                    </JsonExcel>
                  </v-list-item-content>
                </v-list-item>
              <v-list-item>
                  <v-list-item-icon>
                    <v-icon>mdi-file-delimited</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <JsonExcel :data="bmdData"
                               :fields="bmdFields"
                               name="balancedata.csv"
                               type="csv"
                               >
                      <v-btn text x-small>CSV</v-btn>
                    </JsonExcel>
                  </v-list-item-content>
                </v-list-item>
            </v-list>
          </v-menu>
          <v-spacer></v-spacer>
          <v-tooltip v-model="show2" top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on">
                <v-icon color="primary">mdi-information</v-icon>
              </v-btn>
            </template>
            <span>Definition balans per månad: Minsta balans per månad</span>
          </v-tooltip>
        </v-app-bar>
        <v-card-text>
          <BalanceMonthDay></BalanceMonthDay>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
  <v-row>
  <v-col cols="12" class="col-lg-6 col-md-6">
      <v-card class="mx-auto" width="600" elevation="1">
        <v-app-bar elevation="0" color="white">
          <v-menu bottom left v-if="cfData">
            <template v-slot:activator="{on, attrs}">
              <v-app-bar-nav-icon v-bind="attrs" v-on="on">
              </v-app-bar-nav-icon>
            </template>
            <v-list flat dense>
              <v-subheader>HÄMTA SOM:</v-subheader>
                <v-list-item>
                  <v-list-item-icon>
                    <v-icon>mdi-file-excel</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <JsonExcel :data="cfData"
                               :fields="cfFields"
                               name="cashflow.xls"
                               >
                      <v-btn text x-small>Excel</v-btn>
                    </JsonExcel>
                  </v-list-item-content>
                </v-list-item>
              <v-list-item>
                  <v-list-item-icon>
                    <v-icon>mdi-file-delimited</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <JsonExcel :data="cfData"
                               :fields="cfFields"
                               name="cashflow.csv"
                               type="csv"
                               >
                      <v-btn text x-small>CSV</v-btn>
                    </JsonExcel>
                  </v-list-item-content>
                </v-list-item>
            </v-list>
          </v-menu>
          <v-spacer></v-spacer>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on">
                <v-icon color="primary">mdi-information</v-icon>
              </v-btn>
            </template>
            <span>Definition kassaflöde: Summa intäkter och kostnader per månad</span>
          </v-tooltip>
        </v-app-bar>
        <v-card-text>
          <CashFlowChart></CashFlowChart>
        </v-card-text>
      </v-card>
    </v-col>
  <v-col cols="12" class="col-lg-6 col-md-6">
      <v-card class="mx-auto" width="600" elevation="1">
        <v-app-bar elevation="0" color="white">
          <v-menu bottom left v-if="txData">
            <template v-slot:activator="{on, attrs}">
              <v-app-bar-nav-icon v-bind="attrs" v-on="on">
              </v-app-bar-nav-icon>
            </template>
            <v-list flat dense>
              <v-subheader>HÄMTA SOM:</v-subheader>
                <v-list-item>
                  <v-list-item-icon>
                    <v-icon>mdi-file-excel</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <JsonExcel :data="txData"
                               :fields="txFields"
                               name="taxdata.xls"
                               >
                      <v-btn text x-small>Excel</v-btn>
                    </JsonExcel>
                  </v-list-item-content>
                </v-list-item>
              <v-list-item>
                  <v-list-item-icon>
                    <v-icon>mdi-file-delimited</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <JsonExcel :data="txData"
                               :fields="txFields"
                               name="taxdata.csv"
                               type="csv"
                               >
                      <v-btn text x-small>CSV</v-btn>
                    </JsonExcel>
                  </v-list-item-content>
                </v-list-item>
            </v-list>
          </v-menu>
          <v-spacer></v-spacer>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on">
                <v-icon color="primary">mdi-information</v-icon>
              </v-btn>
            </template>
            <span>Definition skattetransaktioner: Summa in och utbetalningar till skattekonto</span>
          </v-tooltip>
        </v-app-bar>
        <v-card-text>
          <TaxPaymentsChart></TaxPaymentsChart>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</v-container>
</template>

<script>
import AccountActivityPerMonth from './charts/AccountActivityPerMonth'
import BalanceMonthDay from './charts/BalanceMonthDay'
import AnalysisWidget from './AnalysisWidget'
import CashFlowChart from './charts/CashFlowChart'
import TaxPaymentsChart from './charts/TaxPaymentsChart'
import JsonExcel from 'vue-json-excel'
import { mapGetters } from 'vuex'
export default {
  name: 'AnalysisCharts',
  components: { TaxPaymentsChart, CashFlowChart, AccountActivityPerMonth, BalanceMonthDay, AnalysisWidget, JsonExcel },
  data: () => ({
    show1: false,
    show2: false,
    items: [
      {
        title: 'Excel-fil',
        icon: 'mdi-file-excel',
        action: 'excel'
      },
      {
        title: 'CSV-fil',
        icon: 'mdi-file-delimited',
        action: 'csv'
      }
    ]
  }),
  computed: {
    ...mapGetters({ CashFlowData: 'StateCashFlow', TaxData: 'StateTax', BalanceData: 'StateBalanceMonthDay', AccountActivityData: 'StateAccountActivity' }),
    aaData: function () {
      if (this.AccountActivityData && this.AccountActivityData.length > 0) {
        const period = this.AccountActivityData[0].period
        const values = this.AccountActivityData[0].values
        const values2 = this.AccountActivityData[1].values
        const combinedArray = period.map(function (value, index) {
          return { period: value, values: values[index], values2: values2[index] }
        })
        return combinedArray
      } else return null
    },
    aaFields: function () {
      return { Period: 'period', In: 'values', Ut: 'values2' }
    },
    bmdData: function () {
      if (this.BalanceData && this.BalanceData.length > 0) {
        const period = this.BalanceData[0].period
        const values = this.BalanceData[0].values
        const combinedArray = period.map(function (value, index) {
          return { period: value, values: values[index] }
        })
        return combinedArray
      } else return null
    },
    bmdFields: function () {
      return { Period: 'period', Value: 'values' }
    },
    cfData: function () {
      if (this.CashFlowData && this.CashFlowData.length > 0) {
        const period = this.CashFlowData[0].period
        const values = this.CashFlowData[0].values
        const combinedArray = period.map(function (value, index) {
          return { period: value, values: values[index] }
        })
        return combinedArray
      } else return null
    },
    cfFields: function () {
      return { Period: 'period', Value: 'values' }
    },
    txData: function () {
      if (this.TaxData && this.TaxData.length > 0) {
        const period = this.TaxData[0].period
        const values = this.TaxData[0].values
        const values2 = this.TaxData[1].values
        const combinedArray = period.map(function (value, index) {
          return { period: value, values: values[index], values2: values2[index] }
        })
        return combinedArray
      } else return null
    },
    txFields: function () {
      return { Period: 'period', In: 'values', Ut: 'values2' }
    }
  }
}
</script>
