<template>
  <span :title="humanFriendlyDate()">
    {{ diffForHumans() }}
  </span>
</template>

<script>
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import localizedTime from 'dayjs/plugin/localizedFormat'
import 'dayjs/locale/sv'
dayjs.locale('sv')
dayjs.extend(relativeTime)
dayjs.extend(localizedTime)
export default {
  name: 'BaseDateTime',
  props: {
    timeStamp: {
      required: true,
      type: String
    }
  },
  methods: {
    diffForHumans () {
      return dayjs(this.timeStamp).fromNow()
    },
    humanFriendlyDate () {
      return dayjs(this.timeStamp).format('llll')
    }
  }
}
</script>
