// store/modules/auth.js

import axios from 'axios'

const state = {
  connections: [],
  aspspList: null
}
const getters = {
  StateConnections: (state) => state.connections,
  StateAspspList: (state) => state.aspspList
}
const actions = {
  async ListConnections ({ commit }, company) {
    const path = 'aspsp/connlist/' + company + '/'
    await axios.get(path).then(function (response) {
      if (response.status === 200) {
        commit('setConnections', response.data)
      }
    }).catch(err => {
      throw new Error(`${err.config.url} not found`)
    })
  },
  async AspspList ({ commit }) {
    await axios.get('aspsp/aspsplist').then(response => {
      if (response.data) {
        commit('setAspspList', response.data)
      }
    }).catch(err => {
      throw new Error(`${err.config.url} not found`)
    })
  }
}

const mutations = {
  setConnections (state, connections) {
    state.connections = connections
    const result = connections.filter(obj => {
      return obj.status === 'C'
    })
    state.isCertified = !!result.length
  },
  setAspspList (state, list) {
    state.aspspList = list
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
