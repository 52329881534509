<template>
  <v-container>
    <h1>Aktiveringar:</h1>
    <MonitoringListTable></MonitoringListTable>
  </v-container>
</template>

<script>
import MonitoringListTable from '../components/MonitoringListTable'
export default {
  name: 'PageMonitoring',
  components: { MonitoringListTable }
}
</script>
