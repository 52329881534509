import axios from 'axios'

const state = {
  comments: null
}
const getters = {
  StateComments: (state) => state.comments
}
const actions = {
  async ListComments ({ commit }, data) {
    const myPath = '/api/comments/?companyId=' + data.companyId + '&creditorId=' + data.creditorId
    await axios.get(myPath).then(response => {
      commit('setComments', response.data)
    })
  },
  async CreateComment ({ dispatch }, data) {
    const Data = new FormData()
    Data.append('comment', data.comment)
    Data.append('company', data.companyId)
    Data.append('creditor', data.creditorId)
    Data.append('user', data.user)
    Data.append('category', data.cat)
    const myPath = '/api/comments/'
    await axios.post(myPath, Data).then(response => {
      if (response.status === 201) {
        return dispatch('ListComments', data)
      }
    })
  },
  async UpdateComment ({ dispatch }, data) {
    const myPath = '/api/comments/' + data.id + '/'
    const MyFormData = new FormData()
    MyFormData.append('comment', data.comment)
    await axios.patch(myPath, MyFormData)
    dispatch('ListComments', data)
  },
  async InactivateComment ({ dispatch }, data) {
    const myPath = '/api/comments/' + data.id + '/'
    const MyFormData = new FormData()
    MyFormData.append('active', 'false')
    await axios.patch(myPath, MyFormData)
    dispatch('ListComments', data)
  }
}

const mutations = {
  setComments (state, comments) {
    state.comments = comments
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
