<template>
  <v-card class="mx-auto" max-width="600px">
    <v-card-title>Kontaktuppgifter</v-card-title>
    <v-card-text>
    <v-form @submit.prevent="submit" v-model="isValid">
      <v-text-field
          label="E-post"
          prepend-icon="mdi-account-circle"
          required
          v-model="form.email"
          :disabled="disabled"
          :rules="emailRules"
          id="email"
      />
      <v-text-field
          label="Telefonnummer"
          prepend-icon="mdi-phone"
          required
          v-model="form.phoneNumber"
          :disabled="disabled"
          id="phoneNumber"
      />
    </v-form>
      </v-card-text>
    <v-divider></v-divider>
    <v-card-actions>
      <v-btn @click="submit" class="primary" :disabled="!isValid || disabled" :loading="isSubmitted">Uppdatera</v-btn>
      <v-btn color="secondary" @click="close">Avbryt</v-btn>
      <v-spacer></v-spacer>
      <v-btn @click="disabled=false" :disabled="!disabled">Ändra</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapActions } from 'vuex'
export default {
  name: 'CompanyContactDialog',
  props: ['email', 'phone', 'companyId', 'creditorId'],
  data: () => ({
    disabled: true,
    isSubmitted: false,
    isValid: true,
    showError: false,
    snackbarText: 'Uppdatering misslyckades',
    timeout: 2000,
    form: {
      email: '',
      phoneNumber: ''
    },
    emailRules: [
      v => !!v || 'Email is required',
      v => /.+@.+/.test(v) || 'E-mail must be valid']
  }),
  created () {
    this.form.email = this.email
    this.form.phoneNumber = this.phone
  },
  methods: {
    ...mapActions(['CreateUpdateCompanyContact']),
    async submit () {
      this.isSubmitted = true
      const data = { email: this.form.email, phoneNumber: this.form.phoneNumber, companyId: this.companyId, creditorId: this.creditorId }
      try {
        await this.CreateUpdateCompanyContact(data)
        this.isSubmitted = false
        this.close()
      } catch (error) {
        this.showError = true
        this.isSubmitted = false
        this.form.email = ''
      }
    },
    close () {
      this.disabled = true
      this.$emit('closeDialog')
    }
  }
}
</script>
