import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import store from './store'
import router from './router'
import axios from 'axios'
import upperFirst from 'lodash/upperFirst'
import camelCase from 'lodash/camelCase'
import * as Sentry from '@sentry/vue'
import { Integrations } from '@sentry/tracing'

axios.defaults.withCredentials = true
axios.defaults.baseURL = process.env.VUE_APP_API_SERVER

axios.interceptors.response.use((response) => {
  return response
}, function (error) {
  const originalRequest = error.config
  if (error.response.status === 403 && originalRequest.url !== 'users/creditor/temptoken/') {
    const logout = new Promise((resolve, reject) => {
      resolve(store.commit('setUser', null))
    })
    logout.then(out => window.location.reload())
  }
  return Promise.reject(error)
})

Vue.config.productionTip = false

const requireComponent = require.context('./components', false, /Base[A-Z]\w+\.(vue|js)$/)
requireComponent.keys().forEach(fileName => {
  const componentConfig = requireComponent(fileName)
  const componentName = upperFirst(
    camelCase(
      fileName
        .split('/')
        .pop()
        .replace(/\.\w+$/, '')
    )
  )
  Vue.component(
    componentName, componentConfig.default || componentConfig
  )
})

Sentry.init({
  Vue: Vue,
  dsn: 'https://1299924c551b464993bc5bfa91bf068b@o504278.ingest.sentry.io/5782561',
  integrations: [new Integrations.BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0
})

new Vue({
  vuetify,
  store,
  router,
  render: h => h(App)
}).$mount('#app')
