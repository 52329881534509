import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'
import PageLogin from '../pages/PageLogin.vue'
import PageCompany from '../pages/PageCompany'
import PageDashBoard from '../pages/PageDashBoard'
import PageMonitoring from '../pages/PageMonitoring'
import PageLogOut from '../pages/PageLogOut'
import PageAlerts from '../pages/PageAlerts'
import PageConditions from '../pages/PageConditions'
import PageResetPassword from '../pages/PageResetPassword'
import PageMaintenance from '../pages/PageMaintenance'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'main',
    component: PageDashBoard,
    meta: { requiresAuth: true }
  },
  {
    path: '/monitoring',
    name: 'monitoring',
    component: PageMonitoring,
    meta: { requiresAuth: true }
  },
  {
    path: '/alerts',
    name: 'alerts',
    component: PageAlerts,
    meta: { requiresAuth: true }
  },
  {
    path: '/login',
    name: 'loginpage',
    component: PageLogin,
    meta: { guest: true }
  },
  {
    path: '/forgot',
    name: 'forgotpage',
    component: PageResetPassword,
    meta: { guest: true }
  },
  {
    path: '/logout',
    name: 'logout',
    component: PageLogOut,
    meta: { guest: true }
  },
  {
    path: '/company/*',
    name: 'company',
    component: PageCompany,
    props: route => ({ query: route.query.q }),
    meta: { requiresAuth: true }
  },
  {
    path: '/conditions/',
    name: 'conditions',
    component: PageConditions,
    meta: { requiresAuth: true }
  },
  {
    path: '/maintenance/',
    name: 'maintenance',
    component: PageMaintenance,
    meta: { guest: true }
  },

  {
    // catch all 404 - define at the very end
    path: '*',
    component: () => import('../pages/PageNotFound.vue')
  }

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (store.getters.isAuthenticated) {
      next()
      return
    }
    next('/login')
  } else {
    next()
  }
})

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.guest)) {
    if (store.getters.isAuthenticated) {
      next()
      return
    }
    next()
  } else {
    next()
  }
})

export default router
