var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.showBankRenewalAlert)?_c('v-alert',{attrs:{"color":"secondary","dark":"","icon":"mdi-alert","border":"left","prominent":""}},[_vm._v(" "+_vm._s(this.renewalText)+" ")]):_vm._e(),_c('v-card',{staticClass:"mx-auto mt-5",attrs:{"width":"1200","elevation":"0"}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.Connections,"sortDesc":true,"hide-default-footer":"","loading":_vm.isLoading,"single-expand":true,"show-expand":""},scopedSlots:_vm._u([{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_vm._v(" Updated by "+_vm._s(item.initiator)+" ")])]}},{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v("Bank")]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('span',{staticClass:"text-left"},[_vm._v("90 Dagars Cert.")]),_c('v-spacer')],1)]},proxy:true},{key:"item.bankLogo",fn:function(ref){
var item = ref.item;
return [_c('v-img',{attrs:{"max-height":"40","max-width":"60","src":_vm.getBankLogoFromBic(item)}})]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [(item.status === 'C')?_c('v-icon',{attrs:{"color":_vm.displayColor(item.status)}},[_vm._v("mdi-check-decagram")]):_vm._e(),(item.status === 'D')?_c('v-icon',{attrs:{"color":_vm.displayColor(item.status)}},[_vm._v("mdi-cancel")]):_vm._e(),(item.status === 'N')?_c('v-icon',{attrs:{"color":_vm.displayColor(item.status)}},[_vm._v("mdi-alert-circle")]):_vm._e()]}},{key:"item.since",fn:function(ref){
var item = ref.item;
return [(item.consent)?_c('tr',[_c('BaseDateTime',{attrs:{"time-stamp":item.consent.datetime_created}})],1):_vm._e()]}},{key:"item.account_status",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.displayAccountStatus(item.account_status))+" ")]}},{key:"item.in_progress",fn:function(ref){
var item = ref.item;
return [(item.in_progress)?_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary"}}):_vm._e()]}},{key:"item.validUntil",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.displayValidUntil(item))+" ")]}},{key:"item.daysLeft",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.displayDaysLeftForBankCon(item))+" ")]}},{key:"item.serverType",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"ma-2",attrs:{"label":"","color":_vm.displayServerColor(item.production_server),"outlined":"","dark":"","small":""}},[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v("mdi-server")]),_vm._v(" "+_vm._s(_vm.displayServerDescription(item.production_server))+" ")],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }