<template>
  <v-container>
    <div v-if="notFound">Företag kan inte hittas</div>
    <v-row>
      <v-col cols="12" class="col-lg-12 col-md-12">
        <v-skeleton-loader v-if="!showCompany && !notFound" v-bind="attrs" type="article, actions"></v-skeleton-loader>
        <company v-if="showCompany"></company>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-skeleton-loader v-if="!showComments && !notFound" v-bind="attrs" type="article, actions"></v-skeleton-loader>
        <CommentListTable v-if="showComments && !notFound"></CommentListTable>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-skeleton-loader v-if="!showBankAccess && isCertified && !notFound" v-bind="attrs" type="article, actions"></v-skeleton-loader>
        <bank-access v-if="isCertified && showBankAccess && !notFound"></bank-access>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" class="col-lg-12 col-md-12">
        <AnalysisCharts v-if="isCertified && !notFound"></AnalysisCharts>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" class="col-lg-12 col-md-12">
        <v-skeleton-loader v-if="!showAlerts && isCertified && !notFound" v-bind="attrs" type="article, actions"></v-skeleton-loader>
        <AlertsListTable v-if="isCertified && showAlerts && !notFound"></AlertsListTable>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Company from '../components/Company'
import BankAccess from '../components/BankAccess'
import CommentListTable from '../components/CommentListTable'
import AlertsListTable from '../components/AlertsListTable'
import AnalysisCharts from '../components/AnalysisCharts'
import { mapGetters, mapActions } from 'vuex'
export default {
  name: 'PageCompany',
  components: { Company, BankAccess, CommentListTable, AnalysisCharts, AlertsListTable },
  props: ['query'],
  data: () => ({
    attrs: {
      class: 'mb-6',
      boilerplate: false,
      elevation: 1
    },
    showCompany: false,
    showBankAccess: false,
    showComments: false,
    showAlerts: false,
    notFound: false
  }),
  computed: {
    ...mapGetters({ SelectedOrgNumber: 'StateSelectedOrgNumber', SelectedCompanyId: 'StateSelectedCompanyId' }),
    creditorId: function () {
      return this.$store.getters.StateUser.creditorId
    },
    isCertified: function () {
      let localObject = false
      if (this.$store.getters.StateMonitoredObject) {
        localObject = this.$store.getters.StateMonitoredObject.cert_status === 'A'
      }
      // const filteredObject = this.$store.getters.activeRequests(this.SelectedCompanyId).length > 0
      return localObject
    }
  },
  created () {
    this.$store.commit('setSelectedOrgNumber', this.query)
    this.$store.commit('setMonitoredObject', null)
    this.unwatchOrgNumber = this.$store.watch(
      (state, getters) => getters.StateSelectedOrgNumber,
      (newValue, oldValue) => {
        if (newValue !== oldValue) {
          this.notFound = false
          this.showCompany = false
          this.showBankAccess = false
          this.showComments = false
          this.showAlerts = false
          this.fetchData()
        }
      }
    )
  },
  mounted () {
    this.$store.commit('setMonitoredObject', null)
    this.fetchData()
  },
  methods: {
    ...mapActions(['RetrieveCompany', 'ListComments', 'RetrieveAlerts', 'RetrieveCompanyMonitorStatus']),

    async fetchData () {
      // Company Basic data
      try {
        await this.RetrieveCompany(this.SelectedOrgNumber)
        this.showCompany = true
        this.showBankAccess = true
      } catch (error) {
        this.notFound = true
        this.showCompany = false
        this.showBankAccess = false
        this.showComments = false
      }
      // Monitoring status
      const data = { companyId: this.SelectedCompanyId, creditorId: this.creditorId }
      await this.RetrieveCompanyMonitorStatus(data)
      // Comments
      try {
        const myDict = {
          companyId: this.SelectedCompanyId,
          creditorId: this.creditorId
        }
        await this.ListComments(myDict)
        this.showComments = true
      } catch (error) {
        throw new Error(error)
      }
      // Alerts
      try {
        await this.RetrieveAlerts(this.SelectedCompanyId)
        this.showAlerts = true
      } catch (error) {
        throw new Error(error)
      }
    }
  },
  beforeDestroy () {
    this.unwatchOrgNumber()
  }
}
</script>
