// store/modules/auth.js

import axios from 'axios'

const state = {
  analysisSpecific: null,
  analysisGeneral: null,
  conditionsGeneral: null,
  conditionsSpecific: null
}
const getters = {
  StateAnalysisSpecific: (state) => state.analysisSpecific,
  StateAnalysisGeneral: (state) => state.analysisGeneral,
  StateConditionsGeneral: (state) => state.conditionsGeneral,
  StateConditionsSpecific: (state) => state.conditionsSpecific
}
const actions = {
  async FetchAnalysisGeneral ({ commit }, orgNbr) {
    const myPath = '/analysis/result/' + orgNbr
    await axios.get(myPath).then(response => {
      commit('setAnalysisGeneral', response.data)
    })
  },
  async FetchAnalysisSpecific ({ commit }, orgNbr) {
    const myPath = '/api/analysis/' + orgNbr
    await axios.get(myPath).then(response => {
      commit('setAnalysisSpecific', response.data)
    })
  },
  async FetchConditionsSpecific ({ commit }) {
    const myPath = '/api/creditor-conditions/'
    await axios.get(myPath).then(response => {
      commit('setConditionsSpecific', response.data)
    })
  },
  async FetchConditionsGeneral ({ commit }) {
    const myPath = '/api/conditions/'
    await axios.get(myPath).then(response => {
      commit('setConditionsGeneral', response.data)
    })
  },
  async RunAnalysis () {
    const myPath = '/analysis/runanalysis/'
    await axios.post(myPath)
  }
}

const mutations = {
  setAnalysisSpecific (state, analysis) {
    state.analysisSpecific = analysis
  },
  setAnalysisGeneral (state, analysis) {
    state.analysisGeneral = analysis
  },
  setConditionsGeneral (state, conditions) {
    state.conditionsGeneral = conditions
  },
  setConditionsSpecific (state, conditions) {
    state.conditionsSpecific = conditions
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
