<template>
<v-container>
    <v-card width="400" class="mx-auto">
      <v-app-bar flat color="rgba(0, 0, 0, 0)">
        <v-toolbar-items>
          <v-img
              class="mt-3"
              max-height="30"
              max-width="140"
              src="../assets/Credon_logo.svg">
          </v-img>
        </v-toolbar-items>
      </v-app-bar>
      <v-card-title>Återställ lösenord</v-card-title>
      <v-card-text>
        <v-form @submit.prevent="submit" v-model="isValid">
          <v-text-field
              label="Användarnamn"
              prepend-icon="mdi-account-circle"
              required
              v-model="form.email"
              :disabled="isSubmitted"
              :rules="emailRules"
              id="username"
          />
        </v-form>
      </v-card-text>
      <v-card-actions>
          <v-btn type="submit"
                 class="primary"
                 :disabled="!isValid"
                 :loading="isSubmitted"
          >Skicka</v-btn>
        <v-spacer></v-spacer>
        <v-btn type="submit" class="primary" to="/login">Tillbaka</v-btn>
      </v-card-actions>
    </v-card>
</v-container>
</template>

<script>
import { mapActions } from 'vuex'
export default {
  name: 'PasswordReset',
  data: () => ({
    isSubmitted: false,
    isValid: true,
    showError: false,
    emailRules: [
      v => !!v || 'Email is required',
      v => /.+@.+/.test(v) || 'E-mail must be valid'],
    form: {
      email: null
    },
    snackbar: false,
    snackbarText: 'Reset email sent.',
    timeout: 2000
  }),

  methods: {
    ...mapActions(['ResetPassword']),
    async submit () {
      const Email = new FormData()
      Email.append('email', this.form.email)
      this.isSubmitted = true
      try {
        await this.ResetPassword(Email)
        this.isSubmitted = false
        setTimeout(() => this.$router.push({ path: '/' }), 2000)
        this.form.email = ''
        this.snackbar = true
      } catch (error) {
        this.isSubmitted = false
        this.form.email = null
        this.showError = true
        this.snackbar = true
        this.snackbarText = 'We could not find your email...Sorry!'
      }
    },
    sendToggleMessageToParent (msg) {
      this.$emit('messageFromChild', msg)
    }
  }
}
</script>
