<template>
  <v-app-bar app elevation="1" height="74px">
    <v-toolbar-title class="display-5">Andra chansen - <strong>{{ company }}</strong></v-toolbar-title>
    <v-spacer></v-spacer>
    <v-form @submit.prevent="submit" v-model="isValid" class="mt-4">
      <v-row justify='end'>
        <v-col>
          <v-text-field
          clearable
          ref="form"
          lazy-validation
          label="Sök organisationsnummer"
          prepend-inner-icon="mdi-magnify"
          single-line
          v-model="searchString"
          :loading="isLoading"
          :rules="searchRule"
          @click:clear="onClearClicked()"
        ></v-text-field>
        </v-col>
        <v-col>
      <v-card-actions class="mt-0">
          <v-btn type="submit"
                 class="primary mt-0 mb-0"
                 :disabled="!isValid"
                 :loading="isLoading"
                 small
          >Sök</v-btn>
      </v-card-actions>
          </v-col>
      </v-row>
    </v-form>
    <v-spacer></v-spacer>
    <v-icon class="mr-2">mdi-account</v-icon>
    <v-toolbar-title v-if="isLoggedIn" class="caption">{{ displayName }}</v-toolbar-title>
  </v-app-bar>
</template>

<script>
import { mapActions } from 'vuex'
export default {
  name: 'NavBar',
  data: () => ({
    searchString: null,
    isLoading: false,
    isValid: true,
    searchRule: [
      v => !!v || 'Orgnummer saknas',
      v => Number.isInteger(Number(v)) || 'Ogiltigt format på organisationsnummer (10 siffror, inga tecken)',
      v => (v || '').length === 10 || '10 siffror'
    ]
  }),
  computed: {
    isLoggedIn: function () { return this.$store.getters.isAuthenticated },
    user: function () { return this.$store.getters.StateUser },
    displayName: function () {
      return this.user.firstName + ' ' + this.user.lastName
    },
    company: function () {
      return this.user.company
    }
  },
  created: function () {
    this.searchString = null
  },
  methods: {
    ...mapActions(['RetrieveCompany', 'RetrieveCompanyMonitorStatus']),
    async submit () {
      console.log(this.isValid)
      this.isLoading = true
      if (this.searchString !== null || this.searchString !== '') {
        await this.$store.commit('setSelectedOrgNumber', this.searchString)
        await this.$router.push({ path: '/company/?q=' + this.searchString }).catch(() => {})
        this.isLoading = false
      }
    },
    onClearClicked () {
      this.$refs.form.reset()
      this.$refs.form.resetValidation()
      this.$refs.form.blur()
    }
  }
}
</script>
<style scoped>
/deep/ .v-text-field{
      width: 400px;
}
</style>
