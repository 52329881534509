import axios from 'axios'

const state = {
  monitoredItems: [],
  monitoredObject: {}
}
const getters = {
  StateMonitoredItems: (state) => state.monitoredItems,
  StateMonitoredObject: (state) => state.monitoredObject,
  activeRequests (state, companyId) {
    return (companyId) => {
      return state.monitoredItems.filter(monitor => (monitor.company_id === companyId && monitor.status === 'A'))
    }
  },
  filteredBy (state, companyId) {
    return (companyId) => {
      return state.monitoredItems.filter(monitor => (monitor.company.org_number === companyId))
    }
  },
  certStatusBy (state, companyId) {
    return (companyId) => {
      const monitoredItem = state.monitoredItems.filter(monitor => (monitor.company.org_number === companyId))
      return monitoredItem[0].cert_status
    }
  }
}
const actions = {
  async ListMonitoredItems ({ commit }, creditorId) {
    const myPath = '/api/monitor/?creditor_id=' + creditorId
    await axios.get(myPath).then(response => {
      commit('setMonitoredItems', response.data)
    })
  },
  async CreateMonitoringForCompany ({ dispatch }, data) {
    const myPath = '/api/monitor/' + data.companyId + '/request/'
    const MyFormData = new FormData()
    MyFormData.append('creditor_id', data.creditorId)
    MyFormData.append('requested_by_user', data.userId)
    await axios.post(myPath, MyFormData).then(response => {
      if (response.status) {
        return dispatch('RetrieveCompanyMonitorStatus', data)
      }
    }).catch(err => {
      throw new Error(`${err.config.url} not found`)
    })
  },
  async UpdateMonitoringStatusForCompany ({ dispatch }, data) {
    const myPath = '/api/monitor/' + data.id + '/'

    const MyFormData = new FormData()
    MyFormData.append('status', data.status)

    await axios.patch(myPath, MyFormData).then(response => {
      if (response.status) {
        return dispatch('RetrieveCompanyMonitorStatus', data)
      }
    }).catch(err => {
      throw new Error(`${err.config.url} not found`)
    })
  },
  async RetrieveCompanyMonitorStatus ({ commit }, data) {
    const myPath = '/api/monitor/' + data.companyId + '/?creditor_id=' + data.creditorId
    await axios.get(myPath).then(response => {
      commit('setMonitoredObject', response.data[0])
    }).catch(err => {
      throw new Error(`${err.config.url} not found`)
    })
  }
}

const mutations = {
  setMonitoredItems (state, monitoredItems) {
    state.monitoredItems = monitoredItems
  },
  setMonitoredObject (state, company) {
    state.monitoredObject = company
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
