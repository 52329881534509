<template>
  <div class="small">
    <BarChart :chart-data="dataCollection" :options="barOptions"></BarChart>
  </div>
</template>

<script>
import BarChart from './BarChart'
import { mapGetters, mapActions } from 'vuex'
export default {
  name: 'TaxPaymentsChart',
  components: { BarChart },
  computed: {
    ...mapGetters({
      CompanyId: 'StateSelectedCompanyId',
      TaxData: 'StateTax',
      SelectedCompany: 'StateCompany'
    }),
    lastAnnualClosing: function () {
      if (this.SelectedCompany.finance_set.length > 0) {
        const ld = this.SelectedCompany.finance_set[0].end_period
        const d = new Date(ld)
        return d.toLocaleDateString('sv-SE')
      } else return null
    },
    annotationDateLabel: function () {
      const d = new Date(this.lastAnnualClosing)
      const ye = new Intl.DateTimeFormat('sv', { year: 'numeric' }).format(d)
      const mo = new Intl.DateTimeFormat('sv', { month: 'short' }).format(d)
      return (`${mo} ${ye}`)
    },
    yMin: function () {
      if (this.TaxData && this.TaxData.length > 0) {
        return Math.min(this.TaxData[0].values)
      } else return 0
    },
    yMax: function () {
      if (this.TaxData && this.TaxData.length > 0) {
        return Math.max(this.TaxData[0].values)
      } else return 0
    },
    findYMax: function () {
      const isEndOf = (element) => element === this.lastAnnualClosing
      if (this.TaxData && this.TaxData.length > 0) {
        return this.TaxData[0].period.findIndex(isEndOf)
      } else {
        return 0
      }
    },
    noTransactionsAnnotation: function () {
      const annotation1 = {
        type: 'line',
        scaleID: 'B',
        value: 0,
        borderColor: 'white',
        borderWidth: 0,
        label: {
          backgroundColor: 'red',
          content: 'Skattetransaktioner kan inte visas',
          enabled: true
        }
      }
      if (this.TaxData && this.TaxData.length > 0) {
        return {}
      } else {
        return annotation1
      }
    },
    closingAnnotation: function () {
      const annotation = {
        type: 'line',
        id: 'BTV',
        mode: 'vertical',
        display: true,
        scaleID: 'B',
        borderColor: '#646464',
        borderWidth: 4,
        value: this.annotationDateLabel,
        label: {
          content: 'Senaste bokslut',
          enabled: true,
          position: 'middle',
          xAdjust: 15,
          backgroundColor: '#313b39',
          fontSize: 10
        }
      }
      return annotation
    },
    areaAnnotation: function () {
      const annotation = {
        type: 'box',
        yScaleID: 'A',
        xScaleID: 'B',
        xMin: -1,
        xMax: this.findYMax,
        yMin: this.yMin,
        yMax: this.yMax,
        backgroundColor: 'rgba(157,149,149,0.25)'
      }
      return annotation
    },
    barOptions: function () {
      return {
        legend: { display: true },
        title: {
          display: true,
          text: 'Skattetransaktioner per månad'
        },
        scales: {
          yAxes: [{
            id: 'A',
            type: 'linear',
            position: 'left',
            ticks: {
              beginAtZero: true
            }
          }],
          xAxes: [{
            id: 'B'
          }]
        },
        annotation: {
          drawTime: 'afterDraw',
          annotations: [
            this.closingAnnotation,
            this.areaAnnotation,
            this.noTransactionsAnnotation
          ]
        }
      }
    },
    dataCollection: function () {
      if (this.TaxData && this.TaxData.length > 0) {
        return {
          labels: this.convertedDate(this.TaxData[0].period),
          datasets: [
            {
              label: 'Skatteintäkter (sum/mån)',
              yAxisID: 'A',
              backgroundColor: '#29C475',
              data: this.TaxData[0].values,
              fill: false
            },
            {
              label: 'Skattebetalningar (sum/mån)',
              yAxisID: 'A',
              backgroundColor: '#F46451',
              data: this.TaxData[1].values,
              fill: false
            }
          ]
        }
      }
      return {}
    }
  },
  created () {
    this.FetchTax(this.CompanyId)
  },
  methods: {
    ...mapActions(['FetchTax']),
    convertedDate (myDates) {
      if (myDates === undefined) {
        return []
      }
      const y = myDates.map(d => {
        d = new Date(d)
        const ye = new Intl.DateTimeFormat('sv', { year: 'numeric' }).format(d)
        const mo = new Intl.DateTimeFormat('sv', { month: 'short' }).format(d)
        return (`${mo} ${ye}`)
      })
      return y
    }
  },
  beforeDestroy () {
    this.$store.commit('setTax', [])
  }
}
</script>
