<template>
  <v-card width="1200" class="mx-auto mt-5" elevation="0">
    <v-data-table
      :headers="headers"
      :items="Comments"
      sort-by="date_modified"
      :sortDesc=true
      class="elevation-1"
      :loading="isLoadingComments"
      loading-text="Fetching comments..."
      :footer-props=" {
        showFirstLastPage: true,
        'items-per-page-text': 'Notiser per sida'
      }"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>Noteringar</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <v-dialog v-model="dialog" max-width="800px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                  color="primary"
                  dark
                  class="mb-2"
                  v-bind="attrs"
                  v-on="on"
              >Ny
              </v-btn>
            </template>
            <v-card>
              <v-card-title>
                <span class="headline">{{ formTitle }}</span>
              </v-card-title>
              <v-card-text>
                <v-select :items="categories"
                          label="Typ"
                          v-model="selectedCat"
                          :disabled="!editMode"
                >
                </v-select>
              </v-card-text>
              <v-card-text>
                <v-textarea outlined
                            name="input-7-4"
                            label="Kommentar..."
                            v-model="editedItem.comment"
                            :disabled="!editMode">
                </v-textarea>
              </v-card-text>
              <v-card-actions>
                <v-btn color="secondary"
                       text
                       v-if="editedIndex !== -1 && !editMode && enableEdit"
                       @click="unlockForChange"
                >Ändra</v-btn>
                <v-spacer></v-spacer>
                <v-btn color="secondary" v-if="editedIndex === -1"
                    text
                    @click="close"
                >Avbryt
                </v-btn>
                <v-btn
                    color="primary"
                    @click="saveNewComment"
                    :loading="isSubmitted"
                    v-if="editedIndex === -1"
                    :disabled="editedItem.comment === ''"
                >Spara
                </v-btn>
                <v-btn
                    color="primary"
                    @click="editComment"
                    :loading="isSubmitted"
                    v-if="editedIndex !== -1 && editMode"
                >Uppdatera
                </v-btn>
                <v-btn color="secondary"
                       v-if="editedIndex !== -1"
                       @click="close"
                >Stäng
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
            <v-card-title class="headline">Är du säker på att du vill radera?</v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="secondary" text @click="closeDelete">Avbryt</v-btn>
              <v-btn color="primary" text @click="deleteCommentConfirm">OK</v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:item.catIcon="{ item }">
        <v-icon small :color="getCatIconColor(item.category)">
          {{ getCatIcon(item.category) }}
        </v-icon>

      </template>
      <template v-slot:item.userName="{ item }">
        {{ item.user.first_name }} {{ item.user.last_name }}
      </template>
      <template v-slot:item.date="{ item }">
        <BaseDateTime :time-stamp="item.date_created"/>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
        <v-icon
            small
            class="mr-2"
            @click="showComment(item)"
            v-on="on"
            v-bind="attrs"
            color="primary"
        >mdi-eye
        </v-icon>
          </template>
          <span>Visa/Ändra</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
        <v-icon
            small
            class="mr-2"
            @click="deleteComment(item)"
            v-on="on"
            v-bind="attrs"
            color="secondary"
        >mdi-delete
        </v-icon>
          </template>
          <span>Radera</span>
        </v-tooltip>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
export default {
  name: 'CommentListTable',
  data: () => ({
    dialog: false,
    dialogDelete: false,
    isLoadingComments: false,
    isSubmitted: false,
    editMode: true,
    editedIndex: -1,
    editedItem: {
      comment: ''
    },
    categories: [
      { value: 'I', text: 'Information' },
      { value: 'A', text: 'Viktigt' },
      { value: 'D', text: 'Beslut' }
    ],
    selectedCat: 'I'
  }),
  computed: {
    ...mapGetters({ Comments: 'StateComments' }),
    formTitle () {
      return this.editedIndex === -1 ? 'Ny kommentar' : 'Kommentar'
    },
    creditorId: function () {
      return this.$store.getters.StateUser.creditorId
    },
    companyId: function () {
      return this.$store.getters.StateSelectedCompanyId
    },
    headers: function () {
      return [
        { text: '', value: 'catIcon', align: 'start', sortable: true },
        { text: 'Från', value: 'userName', sortable: true },
        { text: 'Text', value: 'comment', sortable: true },
        { text: 'Tid', sortable: true, value: 'date' },
        { text: 'Hantera', value: 'actions', sortable: false }
      ]
    },
    enableEdit: function () {
      const commentUser = this.editedItem.user.client_id
      const currentUser = this.$store.getters.StateUser.client_id
      return currentUser === commentUser
    }
  },
  methods: {
    ...mapActions(['ListComments', 'CreateComment', 'UpdateComment', 'InactivateComment']),
    saveNewComment () {
      this.isSubmitted = true
      try {
        const data = {
          comment: this.editedItem.comment,
          companyId: this.companyId,
          creditorId: this.creditorId,
          user: this.$store.getters.StateUser.client_id,
          cat: this.selectedCat
        }
        this.CreateComment(data)
        this.dialog = false
        this.isSubmitted = false
      } catch (e) {
        throw new Error(e)
      }
    },
    showComment (item) {
      this.editedIndex = this.Comments.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialog = true
      this.editMode = false
    },
    unlockForChange () {
      this.editMode = true
    },
    deleteComment (item) {
      this.editedIndex = this.Comments.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialogDelete = true
    },
    editComment () {
      const data = {
        id: this.editedItem.id,
        companyId: this.companyId,
        creditorId: this.creditorId,
        comment: this.editedItem.comment
      }
      try {
        this.UpdateComment(data)
      } catch (e) {
        throw new Error(e)
      }
      this.close()
    },
    deleteCommentConfirm () {
      const data = {
        id: this.editedItem.id,
        companyId: this.companyId,
        creditorId: this.creditorId
      }
      try {
        this.InactivateComment(data)
      } catch (e) {
        throw new Error(e)
      }
      this.editedItem.comment = ''
      this.dialogDelete = false
    },
    close () {
      this.dialog = false
      this.editMode = true
      this.editedItem.comment = ''
      this.editedIndex = -1
      this.isSubmitted = false
    },
    closeDelete () {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedIndex = -1
      })
    },
    getCatIcon (item) {
      if (item === 'I') return 'mdi-information'
      else if (item === 'D') return 'mdi-gavel'
      else return 'mdi-alert'
    },
    getCatIconColor (item) {
      if (item === 'I') return 'primary'
      else if (item === 'D') return 'accent'
      else return 'secondary'
    }
  },
  watch: {
    dialog (val) {
      val || this.close()
    },
    dialogDelete (val) {
      val || this.closeDelete()
    }
  },
  beforeDestroy () {
    this.$store.commit('setComments', null)
  }

}
</script>
