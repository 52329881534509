<template>
  <div class="small">
    <BarChart :chart-data="dataCollection" :options="barOptions"></BarChart>
  </div>
</template>

<script>
import BarChart from './BarChart'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'CashFlowChart',
  components: { BarChart },
  data: () => ({
  }),
  computed: {
    ...mapGetters({
      CompanyId: 'StateSelectedCompanyId',
      CashFlowData: 'StateCashFlow',
      SelectedCompany: 'StateCompany'
    }),
    lastAnnualClosing: function () {
      if (this.SelectedCompany.finance_set.length > 0) {
        const ld = this.SelectedCompany.finance_set[0].end_period
        const d = new Date(ld)
        return d.toLocaleDateString('sv-SE')
      } else return null
    },
    annotationDateLabel: function () {
      const d = new Date(this.lastAnnualClosing)
      const ye = new Intl.DateTimeFormat('sv', { year: 'numeric' }).format(d)
      const mo = new Intl.DateTimeFormat('sv', { month: 'short' }).format(d)
      return (`${mo} ${ye}`)
    },
    yMin: function () {
      if (this.CashFlowData && this.CashFlowData.length > 0) {
        return Math.min(this.CashFlowData[0].values)
      } else return 0
    },
    yMax: function () {
      if (this.CashFlowData && this.CashFlowData.length > 0) {
        return Math.max(this.CashFlowData[0].values)
      } else return 0
    },
    findYMax: function () {
      const isEndOf = (element) => element === this.lastAnnualClosing
      if (this.CashFlowData && this.CashFlowData.length > 0) {
        return this.CashFlowData[0].period.findIndex(isEndOf)
      } else {
        return 0
      }
    },
    barOptions: function () {
      return {
        legend: { display: true },
        title: {
          display: true,
          text: 'Kassaflöde per månad'
        },
        scales: {
          yAxes: [{
            id: 'A',
            type: 'linear',
            position: 'left',
            ticks: {
              beginAtZero: true
            }
          }],
          xAxes: [{
            id: 'B'
          }]
        },
        annotation: {
          drawTime: 'afterDraw',
          annotations: [{
            type: 'line',
            id: 'BTV',
            mode: 'vertical',
            display: true,
            scaleID: 'B',
            borderColor: '#646464',
            borderWidth: 4,
            value: this.annotationDateLabel,
            label: {
              content: 'Senaste bokslut',
              enabled: true,
              position: 'middle',
              xAdjust: 15,
              backgroundColor: '#313b39',
              fontSize: 10
            }
          },
          {
            type: 'box',
            yScaleID: 'A',
            xScaleID: 'B',
            xMin: -1,
            xMax: this.findYMax,
            yMin: this.yMin,
            yMax: this.yMax,
            backgroundColor: 'rgba(157,149,149,0.25)'
          }]
        }
      }
    },
    dataCollection: function () {
      if (this.CashFlowData && this.CashFlowData.length > 0) {
        const colours = this.CashFlowData[0].values.map((value) => value < 0 ? '#F46451' : '#29C475')
        return {
          labels: this.convertedDate(this.CashFlowData[0].period),
          datasets: [
            {
              label: 'Kassaflöde (sum/mån)',
              yAxisID: 'A',
              backgroundColor: colours,
              data: this.CashFlowData[0].values,
              fill: false
            }
          ]
        }
      }
      return {}
    }
  },
  created () {
    this.FetchCashFlow(this.CompanyId)
  },
  methods: {
    ...mapActions(['FetchCashFlow']),
    convertedDate (myDates) {
      if (myDates === null) {
        return []
      }
      const y = myDates.map(d => {
        d = new Date(d)
        const ye = new Intl.DateTimeFormat('sv', { year: 'numeric' }).format(d)
        const mo = new Intl.DateTimeFormat('sv', { month: 'short' }).format(d)
        return (`${mo} ${ye}`)
      })
      return y
    }
  },
  beforeDestroy () {
    this.$store.commit('setCashFlow', [])
  }
}
</script>
