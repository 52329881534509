<template>
  <v-container>
  <v-row class="mt-16">
      <v-col class="text-center">
        <h2>Planerat underhåll</h2>
        <p>Vi håller just nu på att göra uppdateringar i vårt system, det är uppe igen om en liten stund.</p>
        <p><a href="/login">Login</a></p>
      </v-col>
    </v-row>
</v-container>
</template>

<script>
export default {
  name: 'PageMaintenance'
}
</script>

<style scoped>

</style>
