<template>
<v-container>
  <v-row>
    <v-col cols="4">
      <v-card class="mx-auto" width="300" elevation="1">
        <v-card-title>Relationstatus</v-card-title>
        <v-card-text>
          <RequestStatusChart></RequestStatusChart>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col cols="4">
      <v-card class="mx-auto" width="300" elevation="1">
        <v-card-title>Aktiva</v-card-title>
        <v-card-text>
          <RequestHistoryChart></RequestHistoryChart>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col cols="4">
      <v-card class="mx-auto" width="300" elevation="1">
        <v-card-title>Alerts</v-card-title>
        <v-card-text>
          <AlertHistoryChart></AlertHistoryChart>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
  <v-row>
    <v-col cols="12">
    </v-col>
  </v-row>
</v-container>
</template>

<script>
import RequestStatusChart from '../components/charts/RequestStatusChart'
import RequestHistoryChart from '../components/charts/RequestHistoryChart'
import AlertHistoryChart from '../components/charts/AlertHistoryChart'
import { mapActions } from 'vuex'
export default {
  name: 'PageDashBoard',
  components: { RequestStatusChart, RequestHistoryChart, AlertHistoryChart },
  created () {
    try {
      this.AspspList()
    } catch (error) {
      throw new Error(error)
    }
  },
  methods: {
    ...mapActions(['AspspList'])
  }
}
</script>
